Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getAssessementMethod = "GET";
exports.allAssessementTestApiContentType = "application/json";
exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
exports.getAssessementTestCategoriesUrl = `bx_block_assessmenttest/assessment_types`;
exports.getAttemptedAssessmentsUrl = `bx_block_assessmenttest/user_all_assessments`;
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.btnExampleTitle = "CLICK ME";
exports.mockPassword = "password!";
exports.postQuizURL = "/bx_block_profile/quiz";
exports.postMethod = "POST";
exports.updateQuizURL = "/bx_block_profile/quiz/1";
exports.postQuizImportURL = "/bx_block_profile/quiz/import_csv";
exports.getQuizURL = "/bx_block_profile/quiz/1";
  // Customizable Area End