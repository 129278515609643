import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,Grid,Badge,Popover,MenuItem,IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { mailIcon,bellIcon, termsIcon, termsIconDark, settingsIconDark,settingsIcons, logoutIconDark, logoutIcon, mailIconDark, bellIconDark, modalLogoutImg } from "./assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import CustomModalWeb from '../../../components/src/CustomModal.web';
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.theme ? darkTheme : lightTheme}>
        <MainContainer data-test-id="mainContainer" style={{paddingLeft: this.props.open ? 190 : 50}}>
          <TermsConditions navigation={this.props.navigation} id={""} openTermsModal={this.state.openTermsModal} closeTermsModal={this.closeTermsModal}/>
          <CustomModalWeb
                  data-test-id="newcustommodal"
                  isModalOpen={this.state.isModalOpen}
                  handleClose={this.handleCloseLogoutModal}
                  title="Logging out"
                  description="Are you sure you want to log out of your account ?"
                  btnTitle="Yes, Log out"
                  cancelbtnTitle="Cancel"
                  handleButton={this.handleLogout}
                  statusImage={modalLogoutImg} 
                  theme={this.props.theme}
          />
          <NavBar>
              <Grid container spacing={4} style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                <NavGrid item md={4} xs={4} style={{marginLeft:"-30px"}}>
                  <AdvancedSearch navigation={this.props.navigation} id={""} darkMode={this.props.theme}/>
                </NavGrid>
                <NavGrid item md={4} lg={4}>
                  <NavLinkBox>
                    { (!this.state.isDashboardActive) ? <NavLinkTypography onClick={this.goToDashboardPage} data-test-id="dashboardTabTest">Dashboard</NavLinkTypography>
                    : <NavLinkActiveTypography onClick={this.goToDashboardPage} data-test-id="dashboardActiveTest">Dashboard</NavLinkActiveTypography>
                  }
                    {(this.state.isCourseActive)? <NavLinkActiveTypography onClick={this.goToCoursesPage} data-test-id="courseTabActiveTest">Courses</NavLinkActiveTypography>
                    :<NavLinkTypography onClick={this.goToCoursesPage} data-test-id="courseTabTest">Courses</NavLinkTypography>}
                    {(this.state.isBulletinActive)? <NavLinkActiveTypography onClick={this.goToBulletinPage} data-test-id="bulletinTabActiveTest">Bulletin</NavLinkActiveTypography>
                    :<NavLinkTypography onClick={this.goToBulletinPage} data-test-id="bulletinTabTest">Bulletins</NavLinkTypography>}
                  </NavLinkBox>
                </NavGrid>
                <NavGrid item  md={4} lg={4} style={{marginLeft:"20px"}}>
                  <ProfileBox>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={this.props.theme ? mailIconDark:mailIcon} style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <BadgeContainer badgeContent={0} color="secondary">
                      <img src={this.props.theme ? bellIconDark:bellIcon}  style={{width:"24px",height:"24px"}}/>
                    </BadgeContainer>
                    <ProfileImageNameBox>
                      <img src={this.getProfileImage()} style={{width:"40px",height:"40px",borderRadius:"30px"}}/>
                      <NameBox>
                        <NameTypography data-test-id="nametest">{this.state.profileData.attributes.first_name}</NameTypography>
                        <PositionTypography data-test-id="roletest">{this.state.profileData.attributes.role_id}</PositionTypography>
                      </NameBox> 
                      <IconButton style={{cursor:"pointer"}}aria-describedby="dropdown-popover"
                        onClick={this.handleClick}
                        data-test-id="dropdown-button">
                       <ArrowDropDownRounded style={{color:this.props.theme ? "#FFFFFF":"#1F1F1F",cursor:"pointer"}} /> 
                      </IconButton>
                      <Popover
                        id="dropdown-popover"
                        open={this.state.openMenu}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        disablePortal
                        data-test-id="dropdown-popover"
                      >
                        <MenuItem data-test-id="terms-option" style={{backgroundColor:this.props.theme? "#0D0D0D":"#FFFFFF"}} onClick={this.handleOpenTermsModal}>
                          <img src={this.props.theme? termsIconDark:termsIcon} style={{ width:"18px",height:"18px",marginRight:"5px" }} />
                          <MenuTypography data-test-id="terms-option">Terms of Service</MenuTypography>
                        </MenuItem>
                        <hr style={{ width: "184px", height: "1px", backgroundColor: "#E2E8F0",padding:"0px",margin:"0px"}}/>
                        <hr style={{ width: "184px", height: "1px", backgroundColor: "#E2E8F0",padding:"0px",margin:"0px"}}/>
                        <MenuItem data-test-id="settings-option" style={{backgroundColor:this.props.theme? "#0D0D0D":"#FFFFFF"}} onClick={this.gotoProfilePage}>
                          <img src={this.props.theme ? settingsIconDark:settingsIcons } style={{ width:"18px",height:"18px",marginRight:"5px" }} />
                          <MenuTypography data-test-id="settings-option">Settings</MenuTypography>
                        </MenuItem>
                        <MenuItem data-test-id="logout-option" style={{backgroundColor:this.props.theme? "#0D0D0D":"#FFFFFF"}} onClick={this.handleLogoutButton}>
                          <img src={this.props.theme ? logoutIconDark : logoutIcon} style={{ width:"18px",height:"18px",marginRight:"5px"}} />
                          <MenuTypography data-test-id="logout-option">Log out</MenuTypography>
                        </MenuItem>
                      </Popover>
                    </ProfileImageNameBox>
                  </ProfileBox>
                </NavGrid>
              </Grid>
            </NavBar>
        </MainContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width:"100%",
  backgroundColor: theme.palette.background.default,
  // height: 'inherit',
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))
const NavBar = styled(Box)(({ theme })=> ({
  width: "90%",
  height: "75px",
  padding: "15.79px 36.45px 15.79px 33.5px",
  borderRadius: "18px",
  border:"1px",
  background: theme.palette.background.paper,
  margin:"26px 48px",
  marginLeft:"8%",
  "@media (min-width: 1230px) and (max-width: 1480px)": {
    width:"88%",
    marginLeft:"100px"
  }
}))

const NavGrid = styled(Grid)(({ theme }) => ({
 display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding:"0px 20px",
  paddingLeft:"200px",  
  justifyContent: "flex-start", 
  gap:"50px",
}))
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex", flexDirection: "row", gap: "8px",marginLeft:"20px",
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const NameBox = styled(Box)(({ theme }) => ({

}))
const MenuTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color:theme.palette.text.primary
}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform:"capitalize",
}))
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center"
}))
const NavLinkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.text.secondary,
  cursor:"pointer !important",
  "& .active":{
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    padding: "8px",
    gap: "10px",
    borderRadius: "0px 0px 8px 8px"
  },
  "&:hover":{
    cursor:"pointer",
  }
}))
const NavLinkActiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.action.selected,
  cursor:"pointer !important",
  borderBottom: `2px solid ${theme.palette.action.selected}`,
  padding: "8px",
  gap: "10px",
  borderRadius: "0px 0px 8px 8px",
  "&:hover":{
    cursor:"pointer",
  }
}))
const BadgeContainer = styled(Badge)(({ theme }) => ({
  width: "44px",
  height: "44px",
  padding: "10px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
  cursor: 'pointer'
}))
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}
export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#E2E8F0',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: "#F9F9F9"
      },
      text: {
          primary: '#475569',
          secondary: '#475569',
          hint: '#0F172A',
      },
      action: {
          active: '#F1F5F9',
          selected: '#1A469C',
          focus: '#059669',
          disabled: '#475569'
      },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#1A469C'
      },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#18212E',
          light:"#074525",
          dark:"#BDCFF4"
      },
      background: {
          default: '#0D0D0D',
          paper: "#161616",
      },
      text: {
          primary: '#D0DBEB',
          secondary: '#93A1B5',
          hint: '#D5DDF0'
      },
      action: {
          active: '#70A1FF',
          selected: '#70A1FF',
          focus: '#42EBB5',
          disabled: '#99A7BB'
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#12326F'
      },
  },
  typography: FontStyle
});
// Customizable Area End
