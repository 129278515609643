import { Box, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React, { CSSProperties, useRef, useState, ChangeEvent } from "react";
import Button from "./Button";
import PreviewFileCard from "./Card";
import IconButton from "./IconButton";
import CustomLabel from "./Label";

interface IFileUploadProps {
    id?: string;
    label?: string;
    labelStyle?: CSSProperties;
    fileType?: "video" | "image" | "pdf" | string;
    withoutPreview?: boolean;
    uploadingFileIcon?: string;
    value?: File;
    onChange?: (files: File[] | null) => void;
}

const FileUpload = (props: IFileUploadProps) => {
    const { id, label, labelStyle, fileType = "image", withoutPreview = false, uploadingFileIcon, onChange } = props;

    const fileRef = useRef<HTMLInputElement>(null);

    const [previewUrl, setPreviewUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files?.[0];
            if (!withoutPreview) {
                setPreviewUrl(URL.createObjectURL(file));
            } else {
                setFileName(file.name);
                setFileSize(file.size.toString());
            }
            if (onChange) {
                onChange([file]);
            }
        }
    }
    const handleFileUpload = () => {
        if (fileRef && fileRef.current) {
            fileRef.current.click();
        }
    };
    const removePreviewMedia = () => {
        if (!withoutPreview) {
            setPreviewUrl("");
        } else {
            setFileName("");
            setFileSize("");
        }
        if (onChange) {
            onChange(null);
        }
    }

    if (withoutPreview) {
        return (
            <Container>
                <FileUploadWithoutPreviewZone>
                    <CustomLabel>No file selected</CustomLabel>
                    <label htmlFor="fileFromLocal">
                        <input
                            id="fileFromLocal"
                            type="file"
                            accept={fileType}
                            ref={fileRef}
                            style={{ display: "none" }}
                            onChange={handleChange}
                        />
                        <BrowseButton onClick={handleFileUpload}>Browse file</BrowseButton>
                    </label>
                </FileUploadWithoutPreviewZone>
                {fileName !== "" 
                    && (
                        <PreviewFileCard 
                            thumbnailSrc="" 
                            title={fileName} 
                            subtitle={fileSize} 
                            actionButton={<IconButton onClick={removePreviewMedia}><CloseIcon /></IconButton>} 
                            style={{ marginTop: "5px", width: "70%" }}
                        />
                    )}
            </Container>
        )
    }

    return (
        <Container>
            {label && <Label htmlFor={id} role="alert" style={labelStyle}>{label}</Label>}
            {previewUrl !== ""
                ? (
                    <FilePreview>
                        <ImagePreview src={previewUrl} />
                        <RemoveButton
                            // data-test-id='removeCoverImage'
                            style={{ left: "40%" }}
                            onClick={removePreviewMedia}
                        />
                    </FilePreview>)
                : <label htmlFor="uploadedImage" className="profile-upload-btn">
                    <input
                        id="uploadedImage"
                        // data-test-id="coverImageBtn"
                        type="file"
                        accept="image/*"
                        ref={fileRef}
                        style={{ display: "none" }}
                        onChange={handleChange}
                    />
                    <FileUploadZone>
                        <img src={uploadingFileIcon} />
                        <RowStack style={{ gap: '3px' }}>
                            <SupportText>Drag and drop image , or</SupportText>
                            <Button variant="ghost" onClick={handleFileUpload}>Browse file</Button>
                        </RowStack>
                    </FileUploadZone>
                </label>}
        </Container>
    )
}

export default FileUpload;

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const FilePreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '239px',
  borderRadius: '22px',
  border: `1px solid ${theme.palette.primary.main}`,
  flexDirection: 'column'
}))
const ImagePreview = styled('img')({
    width: "100%", 
    height: "100%", 
    borderRadius: "15px"
})
const RemoveButton = styled(CloseIcon)(({ theme }) => ({
    position: "relative",
    bottom: "85%",
    left: "90%",
    height: "15px",
    width: "15px",
    fill: theme.palette.secondary.main,
    cursor: "pointer"
}))
const FileUploadZone = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "22px",
    height: "239px",
    border: `1px dashed ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.primary.main,
}))
const RowStack = styled(Box)({
    display: "flex",
    alignItems: "center"
})
const FileUploadWithoutPreviewZone = styled(RowStack)(({ theme }) => ({
    height: '90px',
    width: "100%",
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    justifyContent: 'space-between',
    padding: '18px 16px',
}))
const SupportText = styled('span')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    lineHeight: '12px',
}))
const BrowseButton = styled(Button)({
    paddingLeft: "60px", 
    paddingRight: "60px", 
    borderRadius: "30px"
})