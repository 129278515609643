import { Box, InputProps, Input as MuiInput, styled } from "@material-ui/core";
import React, { CSSProperties, forwardRef, ReactNode } from "react";

type TextInputType = | 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text';

interface TextInputProps extends InputProps {
    id?: string;
    inputSize?: 'sm' | 'md' | 'lg' | string;
    type?: TextInputType | string;
    label?: string;
    labelStyle?: CSSProperties;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    placeholder?: string;
    errorText?: string;
}

const TextInput = forwardRef<InputProps, TextInputProps>((props, ref) => {
    const { inputSize = 'md', id, label, labelStyle, errorText, iconLeft, iconRight, ...inputProps } = props;

    return (
        <Container>
            {label && <Label htmlFor={id} role="alert" style={labelStyle}>{label}</Label>}
            <Input 
                id={id}
                ref={ref}
                startAdornment={iconLeft}
                endAdornment={iconRight}
                style={{ height: inputSize === 'lg' ? '56' : inputSize === 'sm' ? '32' : '44', border: !!errorText ? '1px solid #DC2626' : '' }}
                {...inputProps}
            />
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
})

export default TextInput;

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))
const Input = styled(MuiInput)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    padding: '10px 12px',
    appearance: 'none',
    fontFamily: 'Rubik',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.info.dark}`,
    gap: '8px',
    borderRadius: '8px',
    lineHeight: '22px',
    background: theme.palette.background.default,
    '&:active, &:focus, &:focus-within': { border: '2px solid #1A469C' },
    '&:placeholder': { color: '#475569' },
    '& > .MuiInputBase-input': { 
        padding: 0, 
        appearance: 'none', 
        "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
        }, 
    }
}))
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})