import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  styled,Switch,Checkbox,MenuItem,
  Select,
  Radio,
  RadioGroup,Accordion,AccordionSummary,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DragIndicator,ExpandMoreRounded } from "@material-ui/icons";
const addIcon = require("../assets/addIcon.png");
const deleteIcon = require("../assets/deleteIcon.png");
const copyIcon = require("../assets/copyIcon.png");
const uploadIcon = require("../assets/uploadIcon.png");
const helpIcon = require("../assets/helpIcon.png");
const trashIcon = require("../assets/trashIcon.png");
const cvsIcon = require("../assets/cvsIcon.png");
const removeIcon = require("../assets/removeIcon.png");
import CustomLabel from "../../utilities/src/Label";
import ReactQuill from 'react-quill';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssessmentTestController, {
  Props,
} from "./AssessmentTestController";
import { AssessmentItemInterface, AttemptedAssessmentInterface } from "./IAssessment";

export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTabSection=()=>{
    return(
      <TabBox>
        <TopMenuOption onClick={()=>this.handleQuizTabs(0)} data-test-id="quizTabszero" style={{color:(this.state.quizTabs===0)?"#1A469C":"#475569",borderBottom:(this.state.quizTabs===0)?"2px solid #1A469C":"2px solid #475569"}}>Manage Questions</TopMenuOption>
        <TopMenuOption onClick={()=>this.handleQuizTabs(1)} data-test-id="quizTabsone" style={{color:(this.state.quizTabs===1)?"#1A469C":"#475569",borderBottom:(this.state.quizTabs===1)?"2px solid #1A469C":"2px solid #475569"}}>Import Quiz</TopMenuOption>
      </TabBox>
    )
  }
  renderQuizPortions = () => {
    return (
      <>
        {this.state.allQuestions.map((question, index) => {
          return (
            <MainQuestionBox key={index}>
              <QuestionBoxHeader expandIcon={<ExpandMoreRounded style={{width:"24px",height:"24px",color:"#475569"}}/>}>
                <div style={{ display: "flex", flexDirection: "row", gap: "4.36px" }}>
                  <DragIndicator style={{ width: "16px", height: "23.27px" }} />
                  <LessonHeading>
                    Question {index + 1}: {question.content}
                  </LessonHeading>
                </div>
                <div style={{display:"flex",flexDirection:"row",gap:"19px"}}>
                  <img src={copyIcon} style={{width:"24px",height:"24px",cursor:"pointer"}}/>
                  <img src={deleteIcon} style={{width:"24px",height:"24px",cursor:"pointer"}}/>
                </div>              
              </QuestionBoxHeader>
              <AccordionSummary>
                <MainContentBox>
              <div style={{width:"200px",height:"90px"}}>
                <StyledSelect
                  data-test-id="questionTypeSelection"
                  value={question.question_type || ''}
                  onChange={(event) => this.handleQuestionTypeChange(event, index)}
                  displayEmpty
                  style={{ width: "100%", marginTop: "10px" }}
                  MenuProps={StyledMenuProps}
                >
                <StyledMenuItem value="" disabled>
                    Question Type
                </StyledMenuItem>
                <StyledMenuItem value="yes_no">One Correct Answer</StyledMenuItem>
                <StyledMenuItem value="multiple_choice">Multiple Answers</StyledMenuItem>
              </StyledSelect>
              </div>
              <ReactQuill
                ref={this.quillRef}
                data-test-id="txtQuestion"
                value={question.description}
                formats={formats}
                onChange={(event)=>this.handleQuestionAddition(event,index)}
              />
              <div style={{width:"990px",height:"24px",display:"flex",alignItems:"center",justifyContent:"end",flexDirection:"row"}}>
                <img src={uploadIcon} style={{width:"20px",height:"20px"}}/>
                <UploadTypo>Video/Images</UploadTypo>
              </div>
              <RadioGroup name={`question-${index}`}>
              {question.choices_attributes.map((choices,choice_index)=>{
                return (
                  <EachChoiceBox>
                    <QuestionBoxHeader>
                    <div style={{ display: "flex", flexDirection: "row", gap: "4.36px" }}>
                      <DragIndicator style={{ width: "16px", height: "23.27px" }} />
                      <LessonHeading data-test-id="choiceId">
                        choice {choice_index + 1}
                      </LessonHeading>
                    </div>
                  </QuestionBoxHeader>
                  <ReactQuill
                    ref={this.quillRef}
                    data-test-id="txtAnswer"
                    value={question.description}
                    formats={formats}
                    onChange={(event)=>this.handleChoiceAddition(event,index,choice_index)}
                  />
                  <div style={{display:"flex",flexDirection:"row",justifyContent:"left",alignItems:"center"}}>
                  <StyledRadio data-test-id="radioSelection" value={choices.is_correct} checked={choices.is_correct} onClick={(event)=>{this.handleAnswerAddition(event,index,choice_index)}}/>
                  <ChoiceLabel>This is the correct answer</ChoiceLabel>
                  </div>
                  </EachChoiceBox>
                )
              })}
              </RadioGroup>
                <ChoiceButton data-test-id="addChoiceTest" onClick={()=>{this.handleNewOptionAddition(index)}}><img src={addIcon} style={{width:"24px",height:"24px"}}/>Add Choice</ChoiceButton>
                <div style={{width:"990px",height:"24px",display:"flex",alignItems:"center",justifyContent:"end",flexDirection:"row"}}>
              
                <SmallSaveButton style={{justifySelf:"end"}}>save</SmallSaveButton>
              </div>
              </MainContentBox>
              </AccordionSummary>
            </MainQuestionBox>
          );
        })}
      </>
    );
  };
  renderFileImport=()=>{
    return (
    <FileImportBox>
      <QuestionBoxHeader style={{justifyContent:"space-between"}}>
          <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
            <img src={helpIcon} style={{width:"24px",height:"24px"}}/>
            <ImportTypo data-test-id="importSection">
              Bulk Import Questions
            </ImportTypo>
          </div>
          <img src={trashIcon} style={{width:"24px",height:"24px"}}/>
      </QuestionBoxHeader>    
      <CSVBox>
        <SelectTypo>Select CSV file to imoprt</SelectTypo>
        <ImportBox style={{border:(this.state.fileData.fileName ? "none": "1px solid #CBD5E1" )}}>
          {this.state.fileData.fileName ? 
          (<>
            <FileBox>
              <img src={cvsIcon} style={{width:"31px",height:"39px"}}/>
              <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center"}}>
                <FileNameTypo>{this.state.fileData.fileName}</FileNameTypo>
                <FileSizeTypeTypo>{this.state.fileData.filesize}</FileSizeTypeTypo>
                <FileSizeTypeTypo>{this.state.fileData.fileType}</FileSizeTypeTypo>
              </div>
              <img src={removeIcon} style={{width:"23px",height:"23px"}}/>
            </FileBox>
          </>) :
          <NoTypo>No file selected</NoTypo>}
          <input
              data-test-id="csvLessonBtn"
              id="csvLesson"
              type="file"
              accept=".csv"
              ref={this.fileRef}
              style={{ display: "none" }}
              onChange={this.handleFileSelect}
            />
          {this.state.fileData.fileName ? <></>
           : 
          <SaveContinueButton data-test-id="csvfileUpload" onClick={(event)=>this.uploadCSVQuizFile(event)} style={{width:"204px",height:"44px"}}>Browse file</SaveContinueButton>
          }
        </ImportBox>
        {this.state.fileData.fileName ?
          <ImportButtonBox>
            <AddQuestionButton style={{width:"255.4px",height:"44px"}} data-test-id="browseFileButton" onClick={(event)=>this.uploadCSVQuizFile(event)}>Browse File</AddQuestionButton>
            <SaveContinueButton data-test-id="csvfileSend" style={{width:"255.4px",height:"44px"}} onClick={()=>this.sendFile()}>Import file</SaveContinueButton>
          </ImportButtonBox>
        : <></> }
 
        </CSVBox>
    </FileImportBox>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.darkTheme ? darkTheme:lightTheme}>
        {this.state.openQuizSection && this.renderTabSection()}
        <Box>
          <QuizHeadingSection>
            <HeadingTypo>Quiz</HeadingTypo>
            <QuizHeadingDivider />
            <Box style={{ justifyContent: "center", alignItems: "center" }}>
              <CustomLabel style={{ fontWeight: 700 }}>Required</CustomLabel>
              <SwitchDiv style={{ marginTop: 0 }}><Switch /></SwitchDiv>
            </Box>
          </QuizHeadingSection>
          <Box style={{marginTop:"32px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
            {this.state.quizTabs===0 && this.renderQuizPortions()}
            {this.state.quizTabs===1 && this.renderFileImport()}
          </Box>
           <div style={{width:"1050px",height:"200px",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <AddQuestionButton style={{width:"945px"}} data-test-id="addQuestionButton" onClick={this.handleQuizSection}><img src={addIcon} style={{width:"24px",height:"24px"}}/>Add Question</AddQuestionButton>
           </div>

          <PassingGradeSection>
          <div style={{display:"flex",flexDirection:"row",gap:"9px"}}>
          <CheckedBoxButton/>
          <CheckedTypo>This quiz requires a passing grade</CheckedTypo>
          </div>
          <PassingTypo>Passing Grade</PassingTypo>
          <InputField placeholder="1%"/>
          <div style={{display:"flex",flexDirection:"row",gap:"9px"}}>
          <CheckedBoxButton/>
          <CheckedTypo>Randomized bank questions</CheckedTypo>
          </div>
          <div style={{display:"flex",flexDirection:"row",gap:"0px",alignItems:"center"}}>
          <SideBoxes style={{width:"50px",alignItems:"center",justifyContent:"center"}}><SideBoxTypo>Use</SideBoxTypo></SideBoxes>
          <InputField style={{width:"318px",borderRight:"0px",borderLeft:"0px",borderRadius:"0px"}}/>
          <SideBoxes style={{width:"98px"}}><SideBoxTypo>of 0 questions</SideBoxTypo></SideBoxes>
          </div>
          </PassingGradeSection>
          <div style={{width:"945px",height:"44px",gap:"10px",display:"flex",flexDirection:"row"}}>
            <AddQuestionButton style={{width:"473px",height:"44px"}} data-test-id="saveQuestionButton" onClick={this.handleQuizSection}>Save and Close</AddQuestionButton>
            <SaveContinueButton data-test-id="savingQuiz" onClick={this.addNewQuiz}>Save and Add New Lesson Type</SaveContinueButton>
           </div>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RowStack = styled(Box)({ display: "flex" })
const ColStack = styled(RowStack)({ flexDirection: "column" })
const ImportButtonBox = styled(Box)({
  width: "546px",
 height: "76px",
 padding: "0px 0px 32px 18px",
 gap: "10px",
 display:"flex",
 flexDirection:"row",
 alignItems:"center",
 justifyContent:"center",
 marginTop:"32px"
})

const FileNameTypo = styled(Typography)(({ theme }) => ({
  width: "415px",
  fontFamily: "Rubik",
  fontSize: "9.5px",
  fontWeight: 700,
  lineHeight: "14.25px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#0F172A"
  }))

const FileSizeTypeTypo = styled(Typography)(({ theme }) => ({
   fontFamily: "Rubik",
  fontSize: "7.5px",
  fontWeight: 400,
  lineHeight: "14.25px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))

const FileBox = styled(Box)({
  width: "526px",
  height: "72px",
  padding: "6.05px 0px 0px 0px",
  gap: "12.1px",
  borderRadius: "6.05px",
  opacity: "0px",
  background: "#FFFFFF",
  border: "0.76px solid #FFFFFF",
  boxShadow: "0px 2px 8px 0px #00000014",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"
})
const QuizHeadingSection = styled(RowStack)({
  alignItems:"center",
  justifyContent: "space-between",
})
const CommonTypo = styled(Typography)({ fontFamily: "Rubik" })
const HeadingTypo = styled(CommonTypo)({
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "38px",
  color: "#0F172A"
})
const QuizHeadingDivider = styled('hr')({
  width: "231px",
  height: "2px",
  backgroundColor: "#D7D7D7",
  opacity: "50%",
})
const RequestTypo = styled(Typography)({
  width: "64px",
  height: "22px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
})
 const CheckedTypo = styled(Typography)(({ theme }) => ({
  width: "236px",
  height: "22px",
  gap: "0px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#475569"

}))

export const SwitchDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
  "& p": {
    marginLeft: '5px',
    marginTop: '2px',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
    color: theme.palette.text.primary
  },
  '& .MuiSwitch-root': {
    height: '28px',
    padding: 0,
    width: '48px'
  },
  '& .MuiIconButton-root': {
    padding: '2px !important'
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    background: '#E2E8F0',
    borderRadius: '40px',
  },
  "& .MuiSwitch-thumb": {
    height: 24,
    width: 24
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#059669"
  }
}));
const AddQuestionButton = styled(Button)(({ theme }) => ({
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: "1px solid #1A469C",
  textTransform:"none",
  "& .MuiButton-label":{
	fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#1A469C"  
}
}))
const PassingGradeSection = styled(Box)(({ theme }) => ({
  height: "250px",
  padding: "34px 18px 34px 18px",
  gap: "12px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: "#F9F9F9",
  display:"flex",
  flexDirection:"column"
}))
const TabBox = styled(Box)(({ theme }) => ({
  width: "647px",
  height: "30px",
  gap: "0px",
  opacity: "0px",
  borderbottom: "2px solid #D7D7D7",
  display:"flex",
  flexDirection:"row",
  marginBottom:"50px"
}))
const CheckedBoxButton = styled(Checkbox)(({ theme }) => ({
  width: "20px",
   height: "20px",
   gap: "0px",
   borderRadius: "6px",
   opacity: "0px",
   border: "1px solid #475569",
 "& .MuiButton-label":{
    width: "236px",
   height: "22px",
   gap: "0px",
   fontFamily: "Rubik",
   fontSize: "14px",
   fontWeight: 700,
   lineHeight: "22px",
   textAlign: "left",
   textUnderlinePosition: "from-font",
   textDecorationSkipInk: "none",
   color: "#475569"
 }
 }))
 const PassingTypo = styled(Typography)(({ theme }) => ({
 width: "94px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))
const InputField = styled('input')(({ theme }) => ({
  width: "200px",
  height: "44px",
  padding: "10px 12px 10px 16px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #CBD5E1",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#475569",
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'transparent'
  },
  '& .MuiInput-underline:after': {
      borderBottom: 'transparent'
  }
}))
const SideBoxes = styled(Box)(({ theme }) => ({
  height: "42px",
  padding: "12px 8px 12px 8px",
  gap: "10px",
  borderRadius: "6px",
  opacity: "0px",
  backgroundColor: "#E2E8F0",
  border: "1px solid #E2E8F0"
}))
const SideBoxTypo = styled(Typography)(({ theme }) => ({
width: "80px",
  height: "18px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))
const CustomTab=styled(Typography)(({theme})=>({
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '16px',
  textAlign: 'center',
  color: '#475569',
  '& .Mui-selected': {
    color: '#1A469C',
    textDecoration: 'underline',
  },
}))
const TopMenuOption = styled('span')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  textAlign: 'center',
  padding: "10px",
  borderBottom: `2px solid #1A469C`,
  color: "#475569",
  width: "26.67%"
}))
const MainQuestionBox = styled(Accordion)(({ theme }) => ({
  padding: "8px 32px 8px 32px",
  gap: "17px",
  borderRadius: "18px !important",
  opacity: "0px",
  background: "#FFFFFF",
  border: "0.5px solid #E2E8F0",
  "& .ql-container": {
    borderRadius: '8px',
    height: "174px",
    width:"936px",
  },
  '& .quill': {
    width:"936px",
    position: 'relative',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
  },
  "& .ql-toolbar": {
    borderRadius: '8px',
    border:"none",
    position: "absolute",
    bottom: "10px",
    left: 10,
    transform: "translateY",
    zIndex: 1000,
    '& .ql-formats': {
      marginRight: 0
    },
    '& .ql-formats:first-child': {
      display: 'none'
    },
    '& .ql-formats button': {
      border: '1px solid #94A3B8',
      marginRight: 2
    },
  },
  "& .ql-editor": {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#0F172A"
  },
  "& .ql-editor.ql-blank::before": {
    color: "#0F172A",
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontSize: '14px'
  },
}))
const QuestionBoxHeader = styled(AccordionSummary)(({ theme }) => ({
  width: "950px", height: "44px", justifyContent: "space-between",display:"flex",flexDirection:"row",
  "& .MuiAccordionSummary-content":{
  justifyContent:"space-between"
  }
}))
const LessonHeading = styled(Typography)(({ theme }) => ({
 height: "24px",
 gap: "0px",
 opacity: "0px",
 fontFamily: "Rubik",
 fontSize: "20px",
 fontWeight: 500,
 lineHeight: "23.7px",
 textAlign: "center",
 textUnderlinePosition: "from-font",
 textDecorationSkipInk: "none",
 color: "#334155"
}))
const StyledSelect = styled(Select)(({ theme }) => ({
  width: "184px",
  height: "36px",
  padding: "6px 4px 6px 16px",
  gap: "10px",
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #E2E8F0",
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#475569",
  "&.Mui-selected": {
    color: "#1A469C",
    backgroundColor:"#FFFFFF"
  },
  "&.Mui-selected:hover":{
    backgroundColor:"#FFFFFF",
    color:"#1A469C"
  },
  "& :hover":{
    backgroundColor:"#FFFFFF"
  }
}));

const StyledMenuProps = {
  PaperProps: {
    style: {
      width: "181px",
      height: "92px",
      padding: "8px 0px",
      gap: "0px",
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #F1F5F9",
      boxShadow: "0px 2px 4px 0px #00000026",
    },
  },
};
const UploadTypo = styled(Typography)(({ theme }) => ({
  width: "120px",
  height: "24px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#1A469C"
}))
const ChoiceButton = styled(Button)(({ theme }) => ({
  width: "170px",
  height: "32px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: "1px solid #1A469C",
"& .MuiButton-label":{
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#1A469C"
}
}))
const EachChoiceBox = styled(Box)(({ theme }) => ({
  width: "982px",
  height: "323px",
  padding: "17px 21px 17px 21px",
  gap: "8px",
  borderRadius: "9px",
  opacity: "0px",
  backgroundColor: "#F9F9F9",
  border: "1px solid #EDF1F6"
}))
const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.MuiRadio-root": {
    border: "1px solid #475569",
    width: "20px",
    height: "20px",
    borderRadius: "16px",
    transition: "all 0.3s ease",
  },
  "&.Mui-checked": {
    border: "2px solid #475569",
  },
}));
const ChoiceLabel = styled(Typography)(({ theme }) => ({
  width: "180px",
 height: "22px",
 fontFamily: "Rubik",
 fontSize: "14px",
 fontWeight: 400,
 lineHeight: "22px",
 textAlign: "left",
 textUnderlinePosition: "from-font",
 textDecorationSkipInk: "none",
 color: "#475569"
}))
const MainContentBox = styled(Box)(({ theme }) => ({
  width: "982px", height: "1,633px", gap: "29px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"baseline"
}))
const SaveContinueButton = styled(Button)(({ theme }) => ({
  width: "473px",
height: "44px",
padding: "10px 16px 10px 16px",
gap: "8px",
borderRadius: "30px",
opacity: "0px",
backgroundColor: "#1A469C",
textTransform:"none",
"& .MuiButton-label":{
width: "255px",
height: "24px",
fontFamily: "Rubik",
fontSize: "16px",
fontWeight: 700,
lineHeight: "24px",
textAlign: "left",
textUnderlinePosition: "from-font",
textDecorationSkipInk: "none",
color: "#F8FAFC"
},
"&:hover": {
  background: "#1A469C",  
  color: "#F8FAFC"
}
}))
const SmallSaveButton = styled(Button)(({ theme }) => ({
  width: "285.4px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  backgroudColor: "#F1F5F9",
"& .MuiButton-label":{
  width: "37px",
  height: "24px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#43536A"
},
"&:hover": {
  background: "#F1F5F9",  
  color: "#43536A"
}
}))
const FileImportBox = styled(Box)(({ theme }) => ({
  width: "1053px",
  height: "282px",
  gap: "17px",
  borderRadius: "18px",
  backgroundColor: "#F9F9F9"
}))
const ImportTypo = styled(Typography)(({ theme }) => ({
  width: "214px",
  height: "24px",
  fontFamily: "Rubik",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "23.7px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#0F172A"
}))
const CSVBox = styled(Box)(({ theme }) => ({
  width: "1018px",
  height: "117px",
  padding: "0px 9px 0px 9px",
  gap: "5px"
}))
const SelectTypo = styled(Typography)(({ theme }) => ({
  width: "172px",
    height: "22px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#334155"
  }))
  const ImportBox = styled(Box)(({ theme }) => ({
    width: "1000px",
    height: "90px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #CBD5E1",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
  }))
  const NoTypo = styled(Typography)(({ theme }) => ({
    width: "968px",
    height: "24px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#475569"
  }))
const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#E2E8F0',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: '#F9F9F9'
      },
      text: {
          primary: '#334155',
          secondary: '#475569',
          hint: '#0F172A',
      },
      action: {
          active: '#F1F5F9',
          selected: '#1A469C',
          focus: '#059669',
          disabled: '#475569'
      },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#1A469C'
      },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#18212E',
          light:"#074525",
          dark:"#BDCFF4"
      },
      background: {
          default: '#0D0D0D',
          paper: '#161616',
      },
      text: {
          primary: '#D5DDF0',
          secondary: '#93A1B5',
          hint: '#D5DDF0'
      },
      action: {
          active: '#70A1FF',
          selected: '#70A1FF',
          focus: '#42EBB5',
          disabled: '#99A7BB'
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#12326F'
      },
  },
  typography: FontStyle
});
const webstyles = {
  navBar: {
    position: "relative",
    padding: "12px 64px",
    background: "rgb(37, 0, 65)",
    color: "rgb(255, 255, 255)",
  },

  card: {
    position: "relative",
    overflow: "hidden",
    background: "linear-gradient(60deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6))",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    boxShadow: "5px 5px 15px rgb(43, 43, 43)",
  }
}

// Customizable Area End
