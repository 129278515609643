import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AllCategory } from "./FilteroptionsController";
type FilterCategory = 'course' | 'status' | 'sort';
import { getStorageData } from "../../../framework/src/Utilities";

interface SelectedChips {
  course: string[];
  status: string[];
  sort: string[];
  category: string[];
  subCategory: string[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: AllCategory[];
  arrayHolder: AllCategory[];
  isOpen: boolean;
  selectedChips: {
    course: string[];
    status: string[];
    sort: string[];
    category: string[];
    subCategory: string[];

  },
  breadcrumbs: any[],
  allFilters: {
    course: string[];
    status: string[];
    sort: string[];
    category: string[];
    subCategory: string[];
    categoryValue:{id: string,name: string}[];
    subCategoryValue: {id: string,name: string}[];
    sub_subCategoryValue: {id: string,name: string}[];

  };
  courses: {
    image: string;
    course: string;
    group: string;
    category: string;
    subCategory: string;
    status: string
    mostRelavent:boolean,
    percentage:number
  }[];
  selectedTheme: boolean;
  error: string;
  searchQuery: string;
  open:boolean;
  roleId:string;
  currentPage:number;
  totalPage:number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: string = "";
  // Customizable Area Start
  coursesApiCallId: string = "";
  listCategoryApiCallId: string = "";
  subcategoryListApiCallId: string = "";
  apiProfileGETCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      data: [],
      arrayHolder: [],
      isOpen: false,
      allFilters: {
        course: ["Title", "Group"],
        status: ["In progress", "Completed"],
        sort: ["Status", "Relevance"],
        category: ["category1", "category2"],
        subCategory: ["SubCategory 1", "SubCategory 2", "SubCategory 3"],
        categoryValue: [{id: "",name: ""}],
        subCategoryValue: [{id: "",name: ""}],
        sub_subCategoryValue: [{id: "",name: ""}]
      },
      selectedChips: {
        course: [],
        status: [],
        sort: [],
        category: [],
        subCategory: [],
      },
      courses: [],
      selectedTheme: false,
      breadcrumbs: ["Dashboard"],
      error: "",
      searchQuery:"",
      open:false,
      roleId:"",
      currentPage:1,
      totalPage:1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.listCategory()
    this.handleFilterCourses()
    this.subCategoryListApi()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ selectedTheme: true })
    }
    this.getUserProfileData();
    // Customizable Area End
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  navigateToFilter = () => {
    let arrayHolder = this.state.arrayHolder;
    const priceRange = arrayHolder.map(element => element.attributes.price);
    var minimumValue = 0;
    var maximumValue = 0;
    if (
      (typeof priceRange === "number" || priceRange?.length >= 1) &&
      !priceRange
    ) {
      minimumValue = Math.min.apply(null, priceRange);
      maximumValue = Math.max.apply(null, priceRange);
    }
    let params = this.props.navigation.state?.params;
    if (params != undefined) {
      if (params.priceSelectedMin && params.priceSelectedMax) {
        this.props.navigation.push("Filteroptions", {
          min: minimumValue,
          max: maximumValue,
          priceSelectedMin: params.priceSelectedMin,
          priceSelectedMax: params.priceSelectedMax
        });
      }
    } else {
      this.props.navigation.push("Filteroptions", { min: minimumValue, max: maximumValue });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("Message Received", message);
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      runEngine.debugLog("API Message Received", message);

      if (this.coursesApiCallId === apiRequestCallId) {
        if (this.state.roleId === "worker") {
          this.processCoursesResponseWorker(responseJson)
        }
        else {
          this.processCoursesResponse(responseJson);
        }
      }
      if (responseJson) {
        switch (apiRequestCallId) {
          case this.listCategoryApiCallId: {
            const categoryNames = responseJson.data.map((item: any) => {
              return item.attributes?.name;
            });

            const categoryValue = responseJson.data.map((item: any) => {
              return { id: item?.id, name: item.attributes?.name };
            });

            this.setState((prevState) => ({
              allFilters: {
                ...prevState.allFilters,
                category: categoryNames,
                categoryValue: categoryValue,
              },
            }));
            break;
          }

          case this.subcategoryListApiCallId: {
            const subCategoryNames = responseJson.data.map((item: any) => {
              return item.attributes?.name;
            });

            const subCategoryValue = responseJson.data.map((item: any) => {
              return { id: item?.id, name: item.attributes?.name };
            });

            this.setState((prevState) => ({
              allFilters: {
                ...prevState.allFilters,
                subCategory: subCategoryNames,
                subCategoryValue: subCategoryValue,
              },
            }));
            break;
          }
          case this.apiProfileGETCallId:{
            this.setState({
              roleId: responseJson.role_id,
            },()=>{this.setFiltersForRoles()});
            break;
          }

          default:
         return
        }
      }
    }
    // Customizable Area End
  }
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start


  processCoursesResponseWorker(responseJson: any) {
    if (!responseJson.errors) {
      const _coureses = responseJson?.data.map((item: any) => ({
        image: item.attributes.image || "",
        course: item.attributes.course.data.attributes.course_name || "N/A",
        group: item.attributes.group || "N/A",
        category: item.attributes.course.data.attributes.category_name || "N/A",
        subCategory: item.attributes.course.data.attributes.sub_category_name || "N/A",
        status: item.attributes.status || "N/A",
        endTime: item.attributes.expiration_date || 'N/A',
        mostRelavent: item.attributes.course.data.attributes.most_relavent,
        percentage: item.attributes.progress_percentage,
      }));
      this.setState({
        courses: _coureses,
        totalPage: responseJson?.meta?.total_pages || 1,
        currentPage: responseJson?.meta?.current_page || 1
      });
    } else {
      this.setState({
        error: responseJson?.errors || "Unknown error",
        courses: [],
      });
    }
  }

  processCoursesResponse(responseJson: any) {
    if (!responseJson.errors) {
      const _coureses = responseJson?.data.map((item: any) => ({
        image: item.attributes.image || "",
        course: item.attributes.course_name || "N/A",
        group: item.attributes.group_names || "N/A",
        category: item.attributes.category_name || "N/A",
        subCategory: item.attributes.sub_category_name || "N/A",
        status: item.attributes.status || "N/A",
        endTime: item.attributes.end_date || 'N/A',
        mostRelavent: item.attributes.most_relavent,
        percentage: item.attributes.percentage,

      }));
      this.setState({
        courses: _coureses,
        totalPage: responseJson?.meta?.total_pages || 1,
        currentPage: responseJson?.meta?.current_page || 1
      });
    } else {
      this.setState({
        error: responseJson?.errors || "Unknown error",
        courses: [],
      });
    }
  }

  appendFilter = (key: string, filterValue: string, filterKey: 'categoryValue' | 'subCategoryValue', formData: FormData) => {
    const foundItem: { name: string, id: string } | undefined = (this.state.allFilters[filterKey] as { id: string; name: string }[]).find(
      (item) => item.name === filterValue
    );
    foundItem && formData.append(`${key}_id`, foundItem?.id);
  };

  filterCourses = async (query:string) => {
    const header = {
       token: await getStorageData("authToken"),
    };
     let formData = new FormData();
    const selectedFilters: any = this.state.selectedChips;
    const current_page:any=this.state.currentPage
    const filters = Object.keys(selectedFilters);
    formData.append("q",query);
    formData.append("page", current_page);
    filters.forEach((filter) => {
      selectedFilters[filter].forEach((value: string) => {
        if (filter === "category") this.appendFilter("category", value, "categoryValue",formData);
        else if (filter === "subCategory") this.appendFilter("sub_category", value, "subCategoryValue", formData);
        else if (filter === "sort") formData.append(`[filters][sort]`, value);
        else if (filter === "status") formData.append(`[filters][status][]`, value);
        else if (filter === "course") formData.append(`[filters][cource][]`, value);
      });
    })


    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.coursesApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.filterCoursesEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      )
    
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(message.id, message);
  };

  listCategory = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.listCategoryApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.categoryListEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }

  subCategoryListApi = async () => {
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": "application/json",
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.subcategoryListApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.subcategoryListEndPoint);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(message.id, message);
  }

  handleChipClick = (chip: string, type: FilterCategory) => {
    this.setState((prevState) => {
      const selectedChips = { ...prevState.selectedChips };
      const index = selectedChips[type].indexOf(chip);
      if (index === -1) {
        selectedChips[type].push(chip);
        this.addBreadcrumb(chip);
      } else {
        selectedChips[type].splice(index, 1);
        this.removeBreadcrumb(chip);
      }
      return { selectedChips };
    });
  };

  handleRemoveFilter = (type: FilterCategory, filter: string) => {
    this.setState((prevState) => {
      const selectedChips = { ...prevState.selectedChips };
      selectedChips[type] = selectedChips[type].filter(chip => chip !== filter);
      this.removeBreadcrumb(filter);
      return { selectedChips };
    });
  };

  handleRadioboxChange = (option: string, type: FilterCategory) => {
    this.setState((prevState) => {
      const selectedChips = { ...prevState.selectedChips };
      const index = selectedChips[type].indexOf(option);
      if (index === -1) {
        selectedChips[type]=[option]
        this.addBreadcrumb(option);
      } else {
        selectedChips[type].splice(index, 1);
        this.removeBreadcrumb(option);
      }
      return { selectedChips };
    });
  };

  handleBreadcrumbClick = (item: string) => {
    this.setState((prevState) => ({
      breadcrumbs: [...prevState.breadcrumbs, item]
    }));
  };

  addBreadcrumb = (breadcrumb: string) => {
    this.setState((prevState) => {
      const breadcrumbs = [...prevState.breadcrumbs];
      if (!breadcrumbs.includes(breadcrumb)) {
        breadcrumbs.push(breadcrumb);
      }
      return { breadcrumbs };
    });
  };

  removeBreadcrumb = (breadcrumb: string) => {
    this.setState((prevState) => {
      const breadcrumbs = prevState.breadcrumbs.filter(crumb => crumb !== breadcrumb);
      return { breadcrumbs };
    });
  };

  navigatetoPage = (page: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), page);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleInputChange = (event: any) => {
    const value = event.target.value;
    this.setState({ searchQuery: value,currentPage:1 },()=>this.filterCourses(value));
  }

  getUserProfileData=()=>{
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileGETCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileGetURL
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  setFiltersForRoles=()=>{
    if(this.state.roleId==="worker"){
      const newFilters = {
        course: ["title", "group"],
        status: ["in_progress", "completed"],
        sort: ["relevance"],
      }
      this.setState({
        allFilters:{...this.state.allFilters ,...newFilters},
      });
    } else if(this.state.roleId==="instructor"){
      const newFilters = {
        course: [ "company","title", "group"],
        status: ["draft","published","archived"],
        sort: ["creation_date"],
      }
      this.setState({
        allFilters:{...this.state.allFilters ,...newFilters},
      });
    }
    else if(this.state.roleId==="supervisor"){
      const newFilters = {
        course: [ "title", "group","instructor"],
        status: ["published"],
        sort: ["complication_rate"],
      }
      this.setState({
        allFilters:{...this.state.allFilters ,...newFilters},  
      });
    }
  }
  formatStatusFilter = (status: string): string => {
    return status
      .replace(/[_-]/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };
changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value },()=>this.filterCourses(this.state.searchQuery))
  }
  formatStatus = (status: string): string => {
    return status.toUpperCase().replace(/_/g, ' ');
  };

  handleFilterCourses = async () => {
    let selectedFilter = await getStorageData("selectedFilter");
    if (selectedFilter) {
      selectedFilter = JSON.parse(selectedFilter)
      const _slectedFilter: SelectedChips = { sort: selectedFilter.filters.sort, status: selectedFilter.filters.status, course: selectedFilter.filters.cource, category: [], subCategory: [] }
      this.setState({ currentPage: 1, searchQuery: selectedFilter.searchQuery, selectedChips: _slectedFilter }, () => this.filterCourses(selectedFilter.searchQuery))
    }
    else {
      this.filterCourses(this.state.searchQuery)
    }
  }
  // Customizable Area End
}