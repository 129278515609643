import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
import React, { ChangeEvent, ReactNode, RefObject } from 'react';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import  axios from 'axios';
import { toast } from "react-toastify";
import { RadioChangeEvent } from "antd";
import * as Yup from "yup";
import FormDataBuilder from "../../../blocks/utilities/src/form-data-builder";
import { pdfType, quiz, videoType, textType, audioType, multimediaType, pptType, interactiveType, imageType } from "./assets";

export interface SubLesson {
  title: string;
  content?: string;
  lesson_type: string;
  files?: File|null;
  lesson_timer?: string;
  optional_text?: string;
}

export interface Lessons {
  title: string;
  sub_lessons_attributes: SubLesson[];
}

export interface Chapter {
  title: string;
  lessons_attributes: Lessons[];
}

interface Course {
  course_name: string;
  duration: string;
  year: string;
  media_type: string;
  course_description: string;
  category_id: string;
  sub_category_id: string;
  language: string;
  level: string;
  price: string;
  tags: string;
  image: string;
  video: string;
  document: string;
  course_content_attributes: Chapter[];
}

export interface Lesson {
  [key: string]: string
};

interface ProfileAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  date_of_birth: string;
  preferred_name: string;
  role_id: string;
  full_phone_number: string;
  employee_number: string | null;
  location: string | null;
  company_reference: string | null;
  completed_course: string | null;
  account_id: number;
  image: string | null;
  group_id: number;
  group_name: string;
  company_id: number[];
  company_name: string;
  phone_number_verified: boolean;
  selected_theme: string;
  font_size: string;
}

interface ProfileData  {
  id: string;
  type: string;
  attributes: ProfileAttributes;
}

export type ITitleProps = { 
  heading: string; 
  subHeading?: string; 
  enableOfflineDownload?: boolean; 
  actionButton?: ReactNode 
}

export const validationSchema = Yup.object({
  course_name: Yup.string().trim().min(1, 'This field is required'),
  course_description: Yup.string().nullable(),
  image: Yup.string().nullable(),
  video: Yup.string().nullable(),
  category_id: Yup.number().min(0, 'This field is required'),
  sub_category_id: Yup.number().min(0, 'This field is required'),
  time_for_supervisor_to_review: Yup.string().nullable(),
  start_date: Yup.string().nullable(),
  end_date: Yup.string().nullable(),
  tags: Yup.string().nullable(),
});
export const ChapterSchema = Yup.object({
  title: Yup.string().min(1, 'This field is required')
});
export const SubLessonSchema = Yup.object({
  title: Yup.string().min(1, 'This field is required'),
  lesson_type: Yup.string().min(1, 'This field is required'),
  files: Yup.mixed().nullable(),
  lesson_timer: Yup.string().nullable(),
  optional_text: Yup.string().nullable()
});
export const LessonSchema = Yup.object({
  title: Yup.string().min(1, 'This field is required'),
  sub_lessons_attributes: Yup.array(SubLessonSchema).default([])
});

export const LessonTypes = [
  { name: "video", icon: videoType }, 
  { name: "text", icon: textType }, 
  { name: "pdf", icon: pdfType }, 
  { name: "audio", icon: audioType }, 
  { name: "quiz", icon: quiz }, 
  { name: "multimedia", icon: multimediaType }, 
  { name: "presentation", icon: pptType }, 
  { name: "interactive training", icon: interactiveType }, 
  { name: "image", icon: imageType }
]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type: string, filename: string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { attributes: { [key: string]: string | number | object } }[];
  allSubCategories: { attributes: { [key: string]: string | number | object } }[];
  openLeftMenu: boolean;
  darkTheme: boolean;
  CourseInfo: { [key: string]: string | Lesson[] | File | object };
  ChapterInfo: { [key: string]: string | Lesson[] | File | object };
  CategoryInfo: { [key: string]: string | object };
  moduleContent: { [key: string]: string | File };
  allModules: { [key: string]: string | File }[];
  anchorEl: null | HTMLElement;
  publishModal: boolean;
  successModal: boolean;
  publishType: string;
  imagePreview: string;
  videoPreview: string;
  uploadImage: File | null;
  uploadVideo: File | null;
  uploadDoc: File | null;
  suggestions:any;
  activeSuggestionIndex: number | null,
  showDropdown: boolean,
  dropdownPosition: { top: 0, left: 0 }
  content: string,
  selection: any,
  showSuggestions: boolean,
  suggestionBoxPosition: { top: number, left: number},
  open: boolean,
  addNewCourse: boolean;
  addCurriculum: boolean;
  enableChapter: boolean;
  enableListChapter: boolean;
  newChapterAdded: boolean;
  showNewLesson: boolean;
  coverImage: File | null;
  coverPhotoPreview: string;
  promotionalVideo: File | null;
  promotionalVideoPreview: string;
  loading: boolean;
  selectedTagColor: string;
  enableCurriculumChapter: boolean;
  enableLessonSection: boolean;
  lessonType: string;
  enableLessonType: boolean;
  profileData:ProfileData;
  showMainButton:boolean;
  allCourseDetails: Course;
  expandedList:{[key:string]:boolean};
  newChapterName:string;
  newLessonName:string;
  newSubLessonName:string;
  newOptionalText:string;
  newTextContent:string;
  currChapterIndex:number;
  currLessonIndex:number;
  currSubLessonIndex:number;
  addingSubLesson:boolean;
  enableContinue:boolean;
  currentStage:number;
  courseId:number;
  editLessonName:string;
  editLessonType:string;
  editSubLessonName:string;
  editSubLessonContent:string;
  editSubLessonFile:string;
  editChapterName:string;
  editOptionalText:string;
  editChapterIndex:number;
  editLessonIndex:number;
  editSubLessonIndex:number;  
  editChapterFlag:boolean;
  editLessonFlag:boolean;
  editSubLessonFlag:boolean;
  step: number;
  courseDetail: Course;
  isAddingChapter: boolean;
  successModalOpen: boolean;
  isEditingChapter: boolean;
  editableIndex: number;
  isAddingLesson: boolean;
  isEditingLesson: boolean;
  editableLessonIndex: number;
  isSelectingSubLessonType: boolean;
  isAddingSubLesson: boolean;
  isEditingSubLesson: boolean;
  editableSubLessonIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  apiGetSubCategoryCallId: string = "";
  apiGetIndCategoryCallId: string = "";
  apiGetIndSubCategoryCallId: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  quillRef:any;
  apiProfileGETCallId:string="";
  addputApiCallId:string="";
  fileRefs:Record<string, RefObject<HTMLInputElement>>={};
  formikRef: any;
  chapterFormikRef: any;
  lessonFormikRef: any;
  subLessonFormikRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: { data: null, content_type: "", filename: "" },
      selectedCategory: {},
      allCategories: [],
      allSubCategories: [],
      openLeftMenu: false,
      darkTheme: false,
      CourseInfo: {},
      ChapterInfo: {},
      CategoryInfo: {},
      moduleContent: {},
      allModules: [],
      anchorEl: null,
      publishModal: false,
      successModal: false,
      publishType: '',
      imagePreview: '',
      videoPreview: '',
      uploadImage: null,
      uploadVideo: null,
      uploadDoc: null,
      addNewCourse: true,
      addCurriculum: false,
      enableChapter: false,
      enableListChapter: false,
      newChapterAdded: false,
      showNewLesson: false,
      activeSuggestionIndex: null,
      showDropdown: false,
      dropdownPosition: { top: 0, left: 0 },
      content: '',
      selection: null,
      suggestions: [],
      showSuggestions: false,
      suggestionBoxPosition: { top: 0, left: 0 },
      open: false,
      coverImage: null,
      coverPhotoPreview: '',
      loading: false,
      promotionalVideo: null,
      promotionalVideoPreview: '',
      selectedTagColor: "",
      enableCurriculumChapter: false,
      enableLessonSection: false,
      lessonType: "",
      enableLessonType: false,
      profileData: {
        id: "",
        type: "profile",
        attributes: {
          id: 0,
          first_name: null,
          last_name: null,
          email: "",
          date_of_birth: "",
          preferred_name: "",
          role_id: "",
          full_phone_number: "",
          employee_number: null,
          location: null,
          company_reference: null,
          completed_course: null,
          account_id: 0,
          image: null,
          group_id: 0,
          group_name: "",
          company_id: [],
          company_name: "",
          phone_number_verified: false,
          selected_theme: "",
          font_size: "",
        },
      },
      showMainButton:false,
      allCourseDetails:{
        course_name: "",
        duration: "",
        year: "",
        media_type: "",
        course_description: "",
        category_id: "",
        sub_category_id: "",
        language: "",
        level: "",
        price: "",
        tags: "",
        image: "",
        video: "",
        document: "",
        course_content_attributes: [],
      },
      expandedList:{},
      newChapterName:"",
      newLessonName:"",
      newSubLessonName:"",
      newOptionalText:"",
      newTextContent:"",
      currChapterIndex:0,
      currLessonIndex:0,
      currSubLessonIndex:0,
      addingSubLesson:false,
      enableContinue:false,
      currentStage:0,
      courseId:0,
      editLessonName:"",
      editLessonType:"",
      editSubLessonName:"",
      editSubLessonContent:"",
      editSubLessonFile:"",
      editChapterName:"",
      editOptionalText:"",
      editChapterIndex:0,
      editLessonIndex:0,
      editSubLessonIndex:0,
      editChapterFlag:false,
      editLessonFlag:false,
      editSubLessonFlag:false,
      step: Number(sessionStorage.getItem('step')) || 0,
      courseDetail: {
        course_name: "",
        duration: "",
        year: "",
        media_type: "",
        course_description: "",
        category_id: "",
        sub_category_id: "",
        language: "",
        level: "",
        price: "",
        tags: "",
        image: "",
        video: "",
        document: "",
        course_content_attributes: [],
      },
      isAddingChapter: false,
      successModalOpen: false,
      isEditingChapter: false,
      editableIndex: -1,
      isAddingLesson: false,
      isEditingLesson: false,
      editableLessonIndex: -1,
      isSelectingSubLessonType: false,
      isAddingSubLesson: false,
      isEditingSubLesson: false,
      editableSubLessonIndex: -1
    };
    this.quillRef = React.createRef();
    this.fileRefs = {
      "image" : React.createRef(),
      "video" : React.createRef(),
      "ppt" : React.createRef(),
      "pdfFile" : React.createRef(),
      "mediaFile" : React.createRef(),
      "audio" : React.createRef(),
      "mainImage" : React.createRef(),
      "mainVideo" : React.createRef(),
    }
    this.formikRef = React.createRef();
    this.chapterFormikRef = React.createRef();
    this.lessonFormikRef = React.createRef();
    this.subLessonFormikRef = React.createRef();
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ darkTheme: true })
    }
    else {
      this.setState({ darkTheme: false })
    }

    if (this.quillRef.current) {
      const editor = this.quillRef.current.getEditor();
      const editorElement = editor.root;
      editorElement.setAttribute('spellcheck', 'true');
    }
    this.getAllCategory()
    this.getUserProfileData()
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({ refresh: false, publishModal: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        switch (apiRequestCallId) {
          case this.apiPostItemCallId:
            this.setState({ PostData: responseJson.data, refresh: false });
            break;
        
          case this.addpostApiCallId:
            if (responseJson?.error) {
              this.setState({ loading: false });
              toast.error(responseJson.error);
            } else {
              this.setState({
                refresh: true,
                successModal: true,
                loading: false,
                step: 1,
                courseId: responseJson.course.data.id
              });
            }
            this.setState({ loading: false, courseId: responseJson.course.data.id });
            break;
        
          case this.updatePostApiCallId:
            this.setState({ refresh: true, loading: false, step: this.state.step + 1 });
            this.getPostData();
            break;
          case this.addputApiCallId:
              this.setState({ loading:false });
            break;
          case this.DeleteApiCallId:
            this.getPostData();
            break;
        
          case this.apiGetCategoryCallID:
            this.setState({ allCategories: responseJson.data });
            break;
        
          case this.apiGetSubCategoryCallId:
            this.setState({ allSubCategories: responseJson.data });
            break;
        
          case this.apiGetIndCategoryCallId:
            this.setState({
              CourseInfo: {
                ...this.state.CourseInfo,
                category_name: responseJson.data?.attributes?.name,
              },
            });
            break;
        
          case this.apiGetIndSubCategoryCallId:
            this.setState({
              CourseInfo: {
                ...this.state.CourseInfo,
                sub_category_name: responseJson.data?.attributes?.name,
                sub_category: responseJson.data?.attributes?.id,
              },
            });
            break;        
          case this.apiProfileGETCallId:
            this.setState({ profileData: responseJson.data });
            break;
        }         
      } else if (errorReponse) {
        this.setState({ refresh: false });
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  goToItemDetails(item:{attributes: { name:string, id:string}} | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }
  editNavigation = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, true);
  };
  navigateToDetails = (item:{attributes: { name:string, id:string}}) => {
    this.goToItemDetails(item, false);
  };
  getAllCategory = async () => {
    this.apiGetCategoryCallID = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.getAllCategoryEndPoint,
    });
  }
  getUserProfileData = async () => {
    this.apiProfileGETCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.profileGetURL,
    });
  }
  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };
  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };
  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };
  leftMenuHandle = () => {
    this.setState({ openLeftMenu: !this.state.openLeftMenu })
    setStorageData('open', JSON.stringify(!this.state.openLeftMenu))
  }
  handleTheme = () => {
    this.setState({ darkTheme: !this.state.darkTheme })
    setStorageData('darkMode', `${!this.state.darkTheme}`)
  }
  handleCategoryChange = async (event: ChangeEvent<{ value: unknown }>) => {
    this.formikRef.current.setFieldValue('category_id', event.target.value);
    this.apiGetSubCategoryCallId = await this.apiCall({
      method2: configJSON.validationApiMethodType,
      contentType2: configJSON.validationApiContentType,
      endPoint2:`${configJSON.getAllSubCategoryEndPoint}/?category_id=${event.target.value}`
    });
  }
  closeModals = () => {
    this.setState({ publishModal: false, successModal: false, publishType: "" })
  }
  closeSuccessModal = () => {
    this.setState({ successModalOpen: false })
  }
  changePublishType = (event: RadioChangeEvent) => {
    this.setState({ publishType: event.target.value, CourseInfo: { ...this.state.CourseInfo, ['publish_type']: event.target.value } })
  }
  apiCall = async (data: { [key: string]: any }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );
    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
  checkSpelling = async (content:any) => {
    try {
      const response = await axios.get('https://api.textgears.com/spelling', {
        params: {
          key: 'yJ85cVqAAMVkya7O',
          text: content
        }
      });

  const suggestions1 = response.data.response.errors.map((error:any) => ({
        word: error.bad,
        suggestions: error.better,
      }));
  this.setState({suggestions: suggestions1});
      return suggestions1;
    } catch (error) {
    }
  };
  handleWordClick = (index: number, event: any) => {
    const rect = event.target.getBoundingClientRect();
    this.setState({
      activeSuggestionIndex: index,
      showDropdown: true,
      dropdownPosition: {
        top: rect.bottom,
        left: rect.right
      }
    });
  };
  handleSelectionChange = (range:any) => {
    if (range && range.length > 0) {
      const quill = this.quillRef.current.getEditor();
      const selectedText = quill.getText(range.index, range.length);
      this.checkSpelling(selectedText);

      const bounds = quill.getBounds(range.index, range.length);

      const suggestionBoxPosition = {
      top: bounds.top + bounds.height + window.scrollY+290,
      left: bounds.left + window.scrollX+150
      };

      this.setState({
        selection: range,
        showSuggestions: true,
        suggestionBoxPosition
      });
    } else {
      this.setState({ showSuggestions: false });
    }
  };
  handleSuggestionClick = (suggestion:any) => {
    const { selection } = this.state;
    const quill = this.quillRef.current.getEditor();
    if (selection) {
      quill.deleteText(selection.index, selection.length);
      quill.insertText(selection.index, suggestion);
      quill.setSelection(selection.index + suggestion.length);
    }

    this.setState({ showSuggestions: false });
  };
  handleOpen = () => {
    this.setState({open: true})
  };
  handleClose = () => {
    this.setState({open: false})
  };
  handleCreateCourse = async (values: any) => {
    this.setState({ courseDetail: { ...this.state.courseDetail, ...values } });
    const formData = FormDataBuilder({ ...values, curriculum_outline: 'Abc', price: '15', course_description: 'Abc'  }, undefined, 'course');
    this.setState({ loading: true });
    this.addpostApiCallId = await this.apiCall({
      method2: configJSON.PostAPiMethod,
      endPoint2: configJSON.createPostEndPoint,
      body2: formData
    });
  }
  handleEditChapterClick = (index: number) => {
    this.setState({ isEditingChapter: true, editableIndex: index }, () => {
      this.chapterFormikRef.current.setFieldValue('title', this.state.courseDetail.course_content_attributes[this.state.editableIndex].title);
    });
  }
  handleAddSubLessonClick = (chapterIndex: number, lessonIndex: number) => {
    this.setState({
      isSelectingSubLessonType: true,
      editableIndex: chapterIndex,
      editableLessonIndex: lessonIndex
    })

  }
  handleAddLessonClick = (chapterIndex: number) => {
    this.setState({ isAddingLesson: true, editableIndex: chapterIndex });
  }
  handleEditLessonClick = (chapterIndex: number, lessonIndex: number) => {
    this.setState({ isEditingLesson: true, editableIndex: chapterIndex, editableLessonIndex: lessonIndex });
  }
  handleEditSubLessonClick = (chapterIndex: number, lessonIndex: number, subLessonIndex: number) => {
    this.setState({ 
      isEditingSubLesson: true, 
      editableIndex: chapterIndex, 
      editableLessonIndex: lessonIndex, 
      editableSubLessonIndex: subLessonIndex 
    });
  }
  handleChapter = (values: any) => {
    let chapterList = new Array();
    chapterList = this.state.editableIndex < 0
      ? [ ...this.state.courseDetail.course_content_attributes, { title: values.title, lessons_attributes: [] } ] 
      : [
        ...this.state.courseDetail.course_content_attributes.slice(0, this.state.editableIndex),
        { ...this.state.courseDetail.course_content_attributes[this.state.editableIndex], title: values.title },
        ...this.state.courseDetail.course_content_attributes.slice(this.state.editableIndex +1 , this.state.courseDetail.course_content_attributes.length),
      ];
    this.setState({ 
      courseDetail: { ...this.state.courseDetail, course_content_attributes: chapterList },
      isAddingChapter: false,
      isEditingChapter: false,
      editableIndex: -1,
      successModalOpen: !this.state.isEditingChapter,
    });
  }
  removeChapter = (index: number) => {
    const updatedCourse = this.state.courseDetail;
    updatedCourse.course_content_attributes.splice(index, 1);
    this.setState({ courseDetail: updatedCourse });
  }
  updateLesson = (values: Partial<Lesson>) => {
    const chapterList = this.state.courseDetail.course_content_attributes;
    const currentChapterIndex = this.state.editableIndex;
    const lessonList = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes;
    const currentLessonIndex = this.state.editableLessonIndex;

    const savedToChapterList: Chapter[] = [
      ...chapterList.slice(0, currentChapterIndex),
      { 
        ...chapterList[currentChapterIndex], 
        lessons_attributes: [
          ...lessonList.slice(0, currentLessonIndex),
          {
            ...lessonList[currentLessonIndex], 
            ...values
          },
          ...lessonList.slice(currentLessonIndex + 1, lessonList.length)
        ]
      },
      ...chapterList.slice(currentChapterIndex + 1, chapterList.length)
    ]

    this.setState({
      isEditingLesson: false,
      editableIndex: -1,
      editableLessonIndex: -1,
      courseDetail: { ...this.state.courseDetail, course_content_attributes: savedToChapterList },
    })   
  }
  removeLesson = (index: number, lessonIndex: number) => {
    this.setState(prevState => {
      const chapterList = prevState.courseDetail.course_content_attributes;
      const lessonList = chapterList[index].lessons_attributes;
      const updatedLessonList = [...lessonList.slice(0, lessonIndex), ...lessonList.slice(lessonIndex + 1, lessonList.length)];
      const updatedChapterList = [
        ...chapterList.slice(0, index),
        { ...chapterList[index], lessons_attributes: updatedLessonList },
        ...chapterList.slice(index + 1, chapterList.length)
      ];
      return {
        courseDetail: { ...prevState.courseDetail, course_content_attributes: updatedChapterList }
      }
    });
  }
  updateSubLesson = (values: any) => {
    const chapterList = this.state.courseDetail.course_content_attributes;
    const currentChapterIndex = this.state.editableIndex;
    const lessonList = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes;
    const currentLessonIndex = this.state.editableLessonIndex;
    const subLessonList = this.state.courseDetail.course_content_attributes[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes;
    const currentSubLessonIndex = this.state.editableSubLessonIndex;

    const savedToChapterList: Chapter[] = [
      ...chapterList.slice(0, currentChapterIndex),
      { 
        ...chapterList[currentChapterIndex], 
        lessons_attributes: [
          ...lessonList.slice(0, currentLessonIndex),
          {
            ...lessonList[currentLessonIndex], 
            sub_lessons_attributes: [
              ...subLessonList.slice(0, currentSubLessonIndex),
              { ...subLessonList[currentSubLessonIndex], ...values },
              ...subLessonList.slice(currentSubLessonIndex + 1, subLessonList.length)
            ] 
          },
          ...lessonList.slice(currentLessonIndex + 1, lessonList.length)
        ]
      },
      ...chapterList.slice(currentChapterIndex + 1, chapterList.length)
    ]
    
    return savedToChapterList;
  }
  updateSubLessonAndContinue = (values: any) => {
    const savedToChapterList = this.updateSubLesson(values);
    
    this.setState({ 
      courseDetail: { ...this.state.courseDetail, course_content_attributes: savedToChapterList },
      isAddingSubLesson: false,
      isEditingSubLesson: false,
      isSelectingSubLessonType: true,
      editableIndex: -1,
      editableLessonIndex: -1,
      editableSubLessonIndex: -1,
    });
  }
  updateSubLessonAndClose = (values: SubLesson) => {
    const savedToChapterList = this.updateSubLesson(values);

    this.setState({ 
      courseDetail: { ...this.state.courseDetail, course_content_attributes: savedToChapterList },
      isAddingSubLesson: false,
      isEditingSubLesson: false,
      editableIndex: -1,
      editableLessonIndex: -1,
      editableSubLessonIndex: -1,
    });
  }
  removeSubLesson = (index: number, lessonIndex: number, subLessonIndex: number) => {
    this.setState(prevState => {
      const chapterList = prevState.courseDetail.course_content_attributes;
      const lessonList = chapterList[index].lessons_attributes;
      const subLessonList = chapterList[index].lessons_attributes[lessonIndex].sub_lessons_attributes;
      const updatedSubLessonList = [...subLessonList.slice(0, subLessonIndex), ...subLessonList.slice(subLessonIndex + 1, subLessonList.length)];
      const updatedChapterList = [
        ...chapterList.slice(0, index),
        { 
          ...chapterList[index], 
          lessons_attributes: [
            ...lessonList.slice(0, lessonIndex),
            { ...lessonList[lessonIndex], sub_lessons_attributes: updatedSubLessonList },
            ...lessonList.slice(lessonIndex + 1, lessonList.length)
          ] 
        },
        ...chapterList.slice(index + 1, chapterList.length)
      ];
      return {
        courseDetail: { ...prevState.courseDetail, course_content_attributes: updatedChapterList }
      }
    });
  }
  handleUpdateCurriculum = async () => {
    const formData = FormDataBuilder({ chapters_attributes: this.state.courseDetail.course_content_attributes  }, undefined, 'course');
    this.setState({ loading: true });
    this.updatePostApiCallId = await this.apiCall({
      method2: configJSON.PutAPIMethod,
      endPoint2: `${configJSON.createPostEndPoint}/${this.state.courseId}`,
      body2: formData
    });
  }
  handleLesson = (lessonTitle: string, subLessonType: string) => {
    if (!this.state.isAddingLesson) {
      this.handleSubLesson({ lesson_type: subLessonType })
    } else {
      const currentChapterIndex = this.state.editableIndex;
      const chapterList = this.state.courseDetail.course_content_attributes;
      const lessonList = chapterList[currentChapterIndex].lessons_attributes;
      this.setState({ 
        isAddingLesson: false, 
        courseDetail: { 
          ...this.state.courseDetail, 
          course_content_attributes: [
            ...chapterList.slice(0, currentChapterIndex),
            { 
              ...chapterList[currentChapterIndex], 
              lessons_attributes: [
                ...lessonList, 
                { 
                  title: lessonTitle, 
                  sub_lessons_attributes: [{ title: "", lesson_type: subLessonType }]
                }
              ] 
            },
            ...chapterList.slice(currentChapterIndex + 1, chapterList.length),
          ] 
        },
        editableLessonIndex: lessonList.length,
        editableSubLessonIndex: 0,
        isAddingSubLesson: true
      })
    }
  }
  handleSubLesson = (payload: Pick<SubLesson, 'lesson_type'>) => {
    const currentChapterIndex = this.state.editableIndex;
    const currentLessonIndex = this.state.editableLessonIndex;
    const chapterList = this.state.courseDetail.course_content_attributes;
    const lessonList = chapterList[currentChapterIndex].lessons_attributes;
    const subLessonList = chapterList[currentChapterIndex].lessons_attributes[currentLessonIndex].sub_lessons_attributes;
    this.setState({ 
      isSelectingSubLessonType: false, 
      courseDetail: { 
        ...this.state.courseDetail, 
        course_content_attributes: [
          ...chapterList.slice(0, currentChapterIndex),
          { 
            ...chapterList[currentChapterIndex], 
            lessons_attributes: [
              ...lessonList.slice(0, currentLessonIndex), 
              { 
                ...lessonList[currentLessonIndex],
                sub_lessons_attributes: [...subLessonList, { title: "", lesson_type: payload.lesson_type }]
              },
              ...lessonList.slice(currentLessonIndex + 1, lessonList.length)
            ],
          },
          ...chapterList.slice(currentChapterIndex + 1, chapterList.length),
        ] 
      },
      editableSubLessonIndex: subLessonList.length,
      isAddingSubLesson: true
    })
  }
  toggleExpandedList = (chapterIndex:number,lessonIndex:number) => {
    const key = `${chapterIndex}-${lessonIndex}`;
    this.setState(prevState => ({
      expandedList: {
        ...prevState.expandedList,
        [key]: !prevState.expandedList[key],
      },
    }))
  }
  // Customizable Area End
}
