import { styled } from "@material-ui/core";
import React, { CSSProperties, ReactNode } from "react";

type WithChildren<T = {}> = T & { children?: ReactNode }

export const CustomLabel = ({ children, required = false, style }: WithChildren<{ required?: boolean, style?: CSSProperties }>) => (
    <Label style={style}>{children} {required && <span style={{ fontSize: '8px' }}>(Required <span style={{ color: "#DC2626" }}>*</span>)</span>}</Label>
)

export default CustomLabel;

const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: theme.palette.text.primary,
    lineHeight: '22px'
}))