Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.excludeOutOfStockTxt = "Exclude out of stock";
exports.priceRangeTxt = "Price range";
exports.brandTxt = "Brand";
exports.categoryTxt = "Category";
exports.cancel = "Cancel";
exports.apply = "Apply";
exports.searchForBrandTxt = "Search for a Brand";
exports.searchForCategoryTxt = "Search for a Category";
exports.webFilterOptionsTxt = "Web Filter Options";
exports.emptyBlockTxt = "Empty Block";

exports.filterCoursesEndPoint = "bx_block_advanced_search/search";
exports.apiMethodTypePost = "POST";

exports.categoryListEndPoint = "bx_block_categories/categories";
exports.apiMethodTypeGet = "GET";

exports.subcategoryListEndPoint = "bx_block_categories/sub_categories";
exports.Sub_subcategoryListEndPoint = "bx_block_categories/sub_categories/sub_sub_categories"
exports.profileGetURL = "bx_block_profile/courses/user_role";

exports.filterTxt = "Filter";
// Customizable Area End
