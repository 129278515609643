import React from "react";
// Customizable Area Start
import {
  Typography,
  IconButton,
  Divider,
  Chip as MuiChip,
  Box,
  FormControlLabel,
  styled,
  Grid,
  CardContent,
  InputBase,
  Radio,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import BreadcrumbNavigationPage from "../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { hamburger, flower, nextButton,bg,sortIcon } from "./assets";

const CustomFormControlLabel = styled(FormControlLabel)({
  '& .MuiRadio-colorSecondary.Mui-checked svg': {
    fill: 'blue',
    transform: 'scale(0.8)',
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div': {
    left: 2
  },
  '& .MuiRadio-colorSecondary span div': {
    left: 2
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div svg:first-child': {
    background: '#1A469C',
    borderRadius: '50%'
  },
  '& .MuiRadio-colorSecondary.Mui-checked span div svg:nth-child(2)': {
    fill: 'white',
    background: '#1A469C',
    borderRadius: '50%',
  },
})
// Customizable Area End

import FilteritemsController, {
  Props,
} from "./FilteritemsController";

export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start



  renderChipSection = (title: any, filters: any, selectedChips: any, filterType: any) => (
    <>
      <Text3 variant="h6" style={{ fontWeight: 500, color: "#0F172A", fontSize: 16, fontFamily: "Rubik" }}>{title}</Text3>
      <Box display="flex" flexWrap="wrap">
        {filters?.map((filter: any, index: any) => (
          <StyledChip
            key={index}
            label={this.formatStatusFilter(filter)}
            className={selectedChips?.includes(filter) ? "selected" : ""}
            onClick={() => {
              this.handleChipClick(filter, filterType);
              this.handleBreadcrumbClick(filter);
            }}
            onDelete={
              selectedChips?.includes(filter)
                ? () => this.handleRemoveFilter(filterType, filter)
                : undefined
            }
            data-test-id={`chip-${filter}`}
          />
        ))}
      </Box>
    </>
  );

  renderCheckboxSection = (title: any, filters: any, selectedChips: any, filterType: any) => (
    <>
      <Text3 variant="h6" style={{ fontWeight: 500, color: "#0F172A", fontSize: 16, fontFamily: "Rubik" }}>{title}</Text3>
      <Box maxHeight="120px" overflow="auto" marginBottom="1rem" >
        {filters?.map((option: any, index: any) => (
          <div key={index} data-test-id={`radio-${option}`}>
            <CustomFormControlLabel
              key={index}
              control={
                <Radio
                  checked={selectedChips[0] === option}
                  onChange={() => this.handleRadioboxChange(option, filterType)}
                  value={option}
                  name="radio-buttons-group"
                  data-test-id={`radio-control-${option}`}
                />
              }
              label={option}
              data-test-id={`radio-label-${option}`}
              />
          </div>
        ))}
      </Box>
    </>
  );

  Sidebar = () => (
    <SidebarContainer style={{ padding: "1rem", height: "100%", borderRadius: "15px", marginRight: "15px", boxShadow: "0 0 10px rgba(211, 211, 211, 0.5)" }}>
      <Text3 variant="body1" style={{ fontSize: "16px" }}>
        <img src={hamburger} alt="hamburger" data-test-id="hamburger-icon" />
        <span style={{ marginLeft: '10px', color: "#334155", fontFamily: "Rubik", fontWeight: 500, fontSize: 16 }}>Filter Menu</span>
      </Text3>
      <Divider style={{ margin: "1rem 0" }} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <div>
          {this.renderChipSection("Course", this.state.allFilters.course, this.state.selectedChips.course, "course")}
          {this.renderChipSection("Status", this.state.allFilters.status, this.state.selectedChips.status, "status")}
          {this.renderChipSection("Sort by", this.state.allFilters.sort, this.state.selectedChips.sort, "sort")}
          {this.state.roleId==="worker"&&
          <>
          {this.renderCheckboxSection("Category", this.state.allFilters?.category, this.state.selectedChips.category, "category")}
          {this.renderCheckboxSection("Sub-Category", this.state.allFilters?.subCategory, this.state.selectedChips.subCategory, "subCategory")}  
          </>}
        </div>
        <SaveButton data-test-id="apply-filters-button" onClick={() => this.filterCourses(this.state.searchQuery)}>Apply Filters</SaveButton>
      </div>
    </SidebarContainer>
  );

  CourseList = () => (
    <SidebarContainer>
      <Grid container spacing={2}>
        {this.state.courses.map((item, index) => (
          <Grid item xs={12} key={index} data-test-id={`course-card-${index}`}>
            <Cards key={index} style={{
              display: "flex",
              alignItems: "center",
              boxShadow: "none"
            }}>
              <div style={{fontWeight:"bold"}}>{index + 1}</div>
              <Container style={{
                gap:"16px",
                borderRadius: "18px",
                display: "flex",
                padding: 0,
                width: "80%",
                marginLeft: "10px"
              }}>
                <img
                  style={{ borderRadius: "18px", width: "50%", height: "200px" }}
                  src={item.image||flower}
                  data-test-id={`course-image-${index}`}
                />
                <CardContent style={{
                  flex: 1,
                }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text1 data-test-id={`course-name-${index}`}>Course Name</Text1>

                    <Text2>{item.course}</Text2>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text1 data-test-id={`group-name-${index}`}>Group Name</Text1>
                    <Text2>{item.group}</Text2>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text1 data-test-id={`category-name-${index}`}>Course Category / Sub-Category</Text1>
                    <Text2>{item.category} / {item.subCategory} </Text2>
                  </div>
                  {Boolean(item.percentage) &&
                    <Box position="relative" display="flex" alignItems="center">
                      <Box width="100%">
                        <LinearProgressBar variant="determinate" value={item.percentage || 0}>
                        </LinearProgressBar>
                      </Box>
                      <Box
                        position="absolute"
                        top={-20}
                        right={10}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {item.percentage || 0}%
                        </Typography>
                      </Box>
                    </Box>
                  }
                </CardContent>

              </Container>
              <CardContent style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}>
                
               {this.renderStatus(item,index)}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "right" }}>
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={nextButton}
                    data-test-id={`course-next-button-${index}`}
                  />
                </div>
              </CardContent>
            </Cards>
          </Grid>
        ))}
      </Grid>
    </SidebarContainer>
  );
  renderStatus = (item: any,index:number) => {
    if (item.status === "completed")
      return (<div>
       {item.mostRelavent&& <Typography variant="body2" style={{fontSize:"14px", fontWeight:500,marginBottom :"5px", color:"#334155"}} data-test-id={`course-relevance-${index}`}>
          Most Relevant
        </Typography>}
        <Completed data-test-id="completed">COMPLETED</Completed>
        <Typography variant="body2" style={{ color: "black" }} data-test-id={`course-progress-${index}`}>
        </Typography>
      </div>)

    else if (item.status === "expired")
      return (<div><Expired data-test-id="expired">Expired</Expired>
        <Typography variant="body2" style={{ fontSize:"14px", fontWeight:500,marginBottom :"5px", color:"#334155" }} data-test-id={`course-progress-${index}`}>
        Expiration Date <br/> <span style={{fontWeight:700,color:"#0F172A"}}>{item.endTime}</span>
        </Typography>
      </div>)

    else return (<div>
      <Inprogress data-test-id="inprogressSavetest">{this.formatStatus(item.status)}</Inprogress>      
    </div>)

  };
  renderContent = () => (
    <div>
      {this.state.courses.length === 0 ? (
        this.renderEmpty()
      ) : (
        this.renderCourses()
      )}
    </div>
  )

  renderCourses=()=>(
    <Content style={{ padding: "1rem", borderRadius: "50px", boxShadow: "0 0 10px rgba(211, 211, 211, 0.5)" }}>
      <div>
        {this.CourseList()}
      </div>
      <PaginationBox>
      <Pagination                  
        count={this.state.totalPage}
        page={this.state.currentPage}
        onChange={this.changePage}
        data-test-id="pagination"
      />
      </PaginationBox>
    </Content>
  )
  renderEmpty = () => (
    <div style={{display:'flex',justifyContent:'center'}}>
    <NoSearchContent data-test-id="empty-content">
      <NoSearchTypography data-test-id="empty-no-search">
        "No Search Found"
      </NoSearchTypography>
      <NoSearchDesc data-test-id="empty-explore-text">
        Start exploring and your searches will appear here!
      </NoSearchDesc>
    </NoSearchContent>
    </div>
  )
  renderSort = () => (
    <Dropdown style={{ height: "118px", zIndex: 100 }} data-test-id="sort-dropdown">
    <SortText  >SORT BY</SortText>
    <SortText>Ascending</SortText>       
    <SortText>Decending</SortText>

    </Dropdown>
  )

  // Customizable Area End

  render() {
    return (
      <div>
        {/* Customizable Area Start */}
        <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
          <NavigationMenu navigation={this.props.navigation} id={""} open={this.state.open} theme={this.state.selectedTheme}/>
          <Container>
            <div style={{ marginTop: "25px", display: "flex" }}>
              <div style={{ width: "20%", }}>
                {this.Sidebar()}
              </div>
              <div style={{ height: "100%", borderRadius: "15px", width: "75%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", height: "50px", width: "40%", paddingTop: "10px", borderBottomRightRadius: "15px" }}>
                    <BreadcrumbNavigationPage
                      breadcrumbs={['Dashboard', 'Page name', 'Current page']}
                      gotoPage={this.navigatetoPage.bind(this)}
                    />
                  </div>
                  <Search data-test-id="search-container">
                    <SearchIconWrapper data-test-id="search-icon-wrapper">
                      <SearchIcon data-test-id="search-icon" />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search again"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(event) => this.handleInputChange(event)}
                      data-test-id="search-input"
                      value={this.state.searchQuery}
                    />
                  </Search>
                  <AdvanceSearchWapper> 
                  <AdvanceSearch>
                  Advanced Search
                  </AdvanceSearch>
                  <img src={sortIcon} width="31.2px" height="31.2px" alt="hamburger" data-test-id="sort-icon" />
                  </AdvanceSearchWapper>
                </div>
                {this.renderContent()}
              </div>
            </div>
          </Container>
        </ThemeProvider>
        {/* Customizable Area End */}
      </div>
    );
  }
}

// Customizable Area Start

const Dropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 220,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  textAlign:'left',
  marginTop:"50px"
}));

const StyledChip = styled(MuiChip)(({ theme }) => ({
  textTransform:"capitalize",
  margin: "10px 10px 10px 0px",
  "&.selected": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main
  },
  "&:not(.selected)": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.action.selected,
  },
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
  "&.selected .MuiChip-deleteIcon": {
    display: "block",
    color: theme.palette.primary.main
  },
}));

const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}))

const Text1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light
}))

const Text2 = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main
}))

const Text3 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}))

const SortText = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  height:"44px",
  padding:"12px 16px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left",
  "&:hover": {
    backgroundColor: "#E8EDF5",
    color: "#1A469C",
  },
  color: theme.palette.info.light
}))

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}))

const SaveButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  border: "none",
  color: theme.palette.text.secondary,
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  width: "100%",
  height: "32px",
  fontSize: "16px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: theme.palette.background.default,
  cursor:"pointer",
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "black",
  border: "1px solid #CBD5E1",
}));

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  paddingTop:"8px",
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));

const SidebarContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const Cards = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const CustomRadiobox = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    width: "1em",
    height: "1em",
    borderRadius: "50%",
  },
  color: theme.palette.action.selected,
  "&.Mui-checked": {
    color: theme.palette.action.selected,
  },
}));

const PaginationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height:"29px",
  padding: "25px 4px 6px 4px",
  gap: "20px",
  borderRadius: "18px",
  display:"flex",
  justifyContent:"center",
  opacity: "0px",
  "& .MuiPaginationItem-page.Mui-selected": {
    background: theme.palette.action.selected,
    color: '#FFF',
    minWidth: '29px',
    height: '29px'
  },

}));
const NoSearchContent = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top 30px',
  width: "734.31px",
  height: "372px",
  gap: "10px",
  borderRadius: "4.97px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"left",
  margin:"30px",
}));
const NoSearchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto !important",
  fontSize: "32.25px",
  fontWeight: 700,
  lineHeight: "43px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.text.primary,
  gap: "0px",
  opacity: "0px",
}))
const NoSearchDesc = styled(Typography)(({ theme }) => ({
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "21.1px",
  fontWeight: 400,
  lineHeight: "30.1px",
  textAlign: "left",
  color: theme.palette.text.primary
}))

const Inprogress = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "13.55px",
  textAlign: "left",
  width: "96px",
  whiteSpace:'nowrap',
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))
const Completed = styled(Typography)(({ theme }) => ({
  backgroundColor: '#D1FAE5',
  color: '#059669',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  width: "208x",
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))

const Expired = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FEE2E2',
  color: '#DC2626',
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom:10,
  lineHeight: "18px",
  textAlign: "left",
  width: "208x",
  height: "34px",
  padding: "8px 16px 8px 16px",
  gap: "7.53px",
  borderRadius: "40px",
  opacity: "0px"
}))

const AdvanceSearch = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "40px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color:"#334155"
}))
const AdvanceSearchWapper = styled('div')(({ theme }) => ({
display: "flex", height: "50px",textAlign:"right", borderBottomRightRadius: "15px", 
justifyContent:"space-between",
width: "auto",
marginLeft:'auto',
gap:"40px",
cursor:"pointer",
}));
const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 4.4,
  borderRadius: 9.09,
  background:"#E2E8F0",
  [`& .MuiLinearProgress-colorPrimary`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.action.selected,
  },
}));

// Customizable Area End