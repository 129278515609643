import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  CircularProgress,
  ButtonGroup,
  Avatar,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { ArrowBackIosOutlined, ExpandLess, ExpandMore, KeyboardArrowDown, } from "@material-ui/icons";
import { ISubTask, ITaskList, IAccount, IStudentTaskList, ITask } from "./types";
import { FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { DesignationField, DotSection, NameField } from "../../notifications/src/Notifications.web";
import { Checkbox, Radio } from "antd";
import { calender, deleteIcon, editButton } from './assets'
import { InProgressLabel, AssignedLabel, CompletedLabel } from "../../certificationtracking/src/CertificationTracking.web";
import { RadioDiv, RadioOptionHead, RadioOptionSubHead } from "../../postcreation/src/PostCreation.web";
import { All, allBlack, allManagerWhite, group, groupBlack, specific, SpecificBlack } from "../../postcreation/src/assets";
import { CompanyChecked, CompanyName, CompanyNameSubHead, PublishDiv, SearchInput, SelectedGroupBox, SelectedGroupDiv } from "../../bulletinboard/src/BulletinBoard.web";
import { InputField } from "../../settings2/src/Settings2.web";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { DateField, MenuText, OuterContainer, SideTitle, SubTaskText, TaskDescriptionField, TermsChecked, CheckLine, CheckText } from "./Task.web";
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
// Customizable Area End


export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderField = (item: ISubTask, index: number) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <SubTaskText
        data-test-id="txtSubTask"
        placeholder="Type here your task Name"
        onChange={(event) => this.changeSubTasks(event.target.value, index)}
        value={this.state.subTasks[index].name}
        onKeyPress={this.changeSubTaskFocus}
      />
      <img data-test-id="removeTextField" src={deleteIcon}
        onClick={() => this.deleteField(index)}
      />
    </div>
  )

  allocateTypeModal = () => (
    <FieldModal
      open={this.state.publishModal}
      onCancel={this.openPublishModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h1">Task List</ModalHeading>
          <ModalLine style={{ width: '149px' }} />
          <ModalSubHead variant="h4">Allocate Task</ModalSubHead>
          <ModalLine style={{ width: '149px' }} />
        </div>}
      centered
      footer={null}
      destroyOnClose
      closable={true}
      width={742}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changeAllocateType'
        value={this.state.allocate_type}
        onChange={this.changeAllocateType}
        style={{ width: "100%", padding: "20px", flexWrap: "nowrap" }}
      >
        <div style={{ display: 'flex' }}>
          <ArrowBackIosIcon style={{ marginRight: "200px", marginLeft: '20px', fontSize: '15px' }} onClick={this.openPublishModal} />
          <ModalHeading variant="h5">Task Receiver</ModalHeading>
        </div>
        <FormControlLabel
          value='all_company'
          control={<Radio />}
          labelPlacement="start"
          label={<RadioDiv>
            <img src={this.state.blackTheme ? allBlack : All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The Task will be sent to all groups of all companies “Workers Only”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
        <FormControlLabel
          value='specific_companies'
          control={<Radio />}
          labelPlacement="start"
          label={<RadioDiv>
            <img src={this.state.blackTheme ? SpecificBlack : specific} />
            <div>
              <RadioOptionHead>Specific Companies</RadioOptionHead>
              <RadioOptionSubHead>The task will be sent to specific companies “Workers Only”.</RadioOptionSubHead>
              <RadioOptionSubHead>{this.state.companiesList.slice(0, 2)?.map((item) => `${item.attributes.company_name},`)}and {this.state.companiesList.slice(2)?.length} others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
        <FormControlLabel
          value='all_groups'
          control={<Radio />}
          labelPlacement="start"
          label={<RadioDiv>
            <img src={this.state.blackTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Task will be sent to all groups of all companies.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
        <FormControlLabel
          value='all_managers'
          control={<Radio />}
          labelPlacement="start"
          label={<RadioDiv>
            <img src={this.state.blackTheme ? groupBlack : allManagerWhite} />
            <div>
              <RadioOptionHead>All Managers</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all Managers of all companies.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
        />
      </Radio.Group>
      <BackButton data-test-id="closeAllocateModal" onClick={() => { this.setState({ publishModal: false, allocate_type: "all_company" }) }}>Back</BackButton>
      <NextButton
        data-test-id="addAllocateType"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.callOperation}
        disabled={!this.state.allocate_type}
      >
        {this.state.allocate_type !== "specific_companies" ? "Done" : "Next"}
      </NextButton>
    </FieldModal>
  )

  specificCompaniesModal = () => (
    <FieldModal
      open={this.state.specificCompaniesModal}
      onCancel={this.closeSpecificCompaniesModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">select where to publish</ModalHeading>
          <ModalLine style={{ width: '50px' }} />
          <ModalSubHead variant="subtitle2">publish Info</ModalSubHead>
          <ModalLine style={{ width: '50px' }} />
        </div >}
      footer={null}
      centered
      closable={true}
      destroyOnClose
      style={{ paddingBottom: '40px' }}
      width={650}
    >
      <div style={{ display: 'flex', alignItems: "center" }}>
        <ArrowBackIosOutlined style={{ marginRight: "150px", marginLeft: '20px', fontSize: '15px' }} onClick={this.closeSpecificCompaniesModal} />
        <ModalHeading variant="h5">where to publish</ModalHeading>
      </div>
      <SearchInput
        data-test-id="searchCompaniesKey"
        startAdornment={<SearchIcon style={{ marginLeft: "10px" }} />}
        placeholder="Search"
        onChange={this.changeCompanyKey}
        onKeyPress={this.searchCompanies}
      />
      <div className="scrollModal" style={{ maxHeight: "210", overflowY: 'scroll' }}>
        {this.state.companiesList?.map((item) => (
          <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "15px", marginBottom: "10px", alignItems: "center" }}>
            <RadioDiv>
              <Avatar style={{ marginRight: "15px", width: "32px", height: "32px" }} />
              <div>
                <CompanyName>Company Name</CompanyName>
                <CompanyNameSubHead>{item.attributes.company_name}</CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CompanyChecked
              data-test-id="selectCompanyId"
              checked={this.state.selectedCompanyId.includes(Number(item.attributes.id))}
              onChange={(event) => this.selectCompany(event, item.attributes.id)}
            />
          </div>
        ))}
      </div>
      {this.state.selectedCompanyId.length > 0 && <SelectedGroupDiv>
        {this.state.companiesList?.map((item) =>
        (
          this.state.selectedCompanyId.includes(Number(item.attributes.id)) &&
          <SelectedGroupBox className="scrollModal">
            <RadioDiv>
              <Avatar style={{ marginRight: "10px", width: "21px", height: "23px" }} />
              <div>
                <CompanyName style={{ fontSize: "6px" }}>Company Name</CompanyName>
                <CompanyNameSubHead style={{ fontSize: "10px", lineHeight: "8px" }}>
                  {item.attributes.company_name}
                </CompanyNameSubHead>
              </div>
            </RadioDiv>
            <CloseIcon
              data-test-id="removeCompany"
              style={{ width: "7px", height: "7px" }}
              onClick={() => this.removeCompanies(item.attributes.id)} />
          </SelectedGroupBox>
        ))}
      </SelectedGroupDiv>}
      <BackButton data-test-id="closeSpecificModal" onClick={this.closeSpecificCompaniesModal} style={{ marginLeft: "8px" }}>
        Back
      </BackButton>
      <NextButton
        data-test-id="specificGroupAddBtn"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={() => this.setState({ specificCompaniesModal: false, publishModal: false })}
        disabled={this.state.selectedCompanyId.length == 0}
      >
        Done
      </NextButton>
    </FieldModal >
  )

  AddTaskModal = () => (
    <FieldModal
      data-test-id='addTaskModal'
      open={this.state.isVisibleModal}
      onCancel={() => this.setState({ isVisibleModal: !this.state.isVisibleModal })}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">{this.state.editMode ? "Edit Task" : "Task List"}</ModalHeading>
          <ModalLine style={{ width: '25%' }} />
          <ModalSubHead variant="subtitle2">Allocate Task</ModalSubHead>
          <ModalLine style={{ width: '25%' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={742}
      style={{ paddingBottom: '15px', padding: '20px' }}
    >

      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
        <Avatar alt="Remy Sharp" src={this.state.loginUser.attributes.image} style={{ height: '54px', width: "54px" }} />
        <div style={{ marginLeft: '10px' }}>
          <NameField style={{ fontSize: '14px', marginBottom: '10px' }}>{this.state.loginUser.attributes.preferred_name}</NameField>
          <PublishDiv data-test-id="allocateTypeModal" onClick={this.openPublishModal}>
            {this.state.allocate_type === "all_company" &&
              <div>
                <AllocateTypeImage src={this.state.blackTheme ? allBlack : All} />
                <AllocateTypeText>All</AllocateTypeText>
              </div>}
            {this.state.allocate_type === "specific_companies" &&
              <div><AllocateTypeImage src={this.state.blackTheme ? SpecificBlack : specific} />
                <AllocateTypeText> Specific Companies</AllocateTypeText>
              </div>}
            {this.state.allocate_type === "all_groups" &&
              <div>
                <AllocateTypeImage src={this.state.blackTheme ? groupBlack : group} />
                <AllocateTypeText> All Group</AllocateTypeText>
              </div>}
            {this.state.allocate_type === "all_managers" &&
              <div>
                <AllocateTypeImage src={this.state.blackTheme ? groupBlack : allManagerWhite} />
                <AllocateTypeText> All Managers</AllocateTypeText>
              </div>}
            <KeyboardArrowDown />
          </PublishDiv>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginLeft: "20px", marginTop: "10px", alignItems: "center" }}>
        <DueDateTxt style={{ fontSize: "20px" }}>Due Date*</DueDateTxt>
        <DateField
          data-test-id='dueDateTest'
          placement="bottomRight"
          format="MMM DD,YYYY"
          showToday={false}
          onChange={(event) => this.setState({ dueDuration: String(event) })}
          disabledDate={(event) => event.valueOf() < Date.now()}
          suffixIcon={<img src={calender} />}
          defaultValue={moment(this.state.dueDuration?.toString())}
        />
      </div>

      {!this.state.showSubTask ?
        <>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <SideTitle>
              Task Title*
            </SideTitle>
            <InputField
              data-test-id="inputTitle"
              type="text"
              placeholder="Type here your task title"
              fullWidth
              value={this.state.taskTitle}
              onChange={this.changeTaskTitle}
            />
          </Box>
          <Box sx={{ padding: "10px 10px 0px 20px" }}>
            <SideTitle>
              Task Description*
            </SideTitle>
            <TaskDescriptionField
              data-test-id="inputDescription"
              fullWidth
              multiline
              minRows={4}
              onChange={this.changeTaskDescription}
              placeholder="Type here your task description"
              value={this.state.taskDescription}
              inputProps={{ maxLength: 100 }}
            />
            <CountChar>{this.state.taskDescription.length}/100</CountChar>
          </Box>
        </>
        :
        <div className="scrollModal">
          {this.state.subTasks.map((item, index) => this.renderField(item, index))}
          <AddNewButton data-test-id="btnAddNewTask" variant="text"
            onClick={this.addSubTasks}
          >Add New Task</AddNewButton>
        </div>
      }
      <BackButton
        data-test-id="backButton"
        onClick={this.showAddModal}
        style={{ marginLeft: '20px' }}
      >
        {this.state.showSubTask ? "Back" : 'Cancel'}
      </BackButton>
      <NextButton
        data-test-id="enableAddSubTask"
        style={{ width: '65%', marginLeft: '10px' }}
        onClick={this.selectOperation}
        disabled={!this.state.taskTitle || !this.state.taskDescription || !this.state.dueDuration}
      >
        {this.getButtonText()}
      </NextButton>
      <ToastContainer />
    </FieldModal>
  )

  getButtonText = () => {
    if (this.state.showSubTask) {
      if (this.state.editMode) {
        return "Save"
      } else {
        return 'Done,Allocate'
      }
    } else {
      return 'Next'
    }
  }

  selectOperation = () => {
    if (this.state.showSubTask) {
      if (this.state.editMode) {
        this.editTaskList(this.state.selectedTaskId)
      } else {
        this.addTaskList()
      }
    } else {
      this.setState({ showSubTask: true })
    }
  }

  renderCardSection = (itemTask: ITaskList, profile: IAccount) => {
    let item = itemTask.attributes.task;
    return (
      <CardSection>
        <CardHeader>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CardTitle>{item.attributes.title}</CardTitle>
            <CardSubTitle>{item.attributes.description}</CardSubTitle>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress color="primary" variant="determinate" className="top-circle" value={itemTask.attributes.progress_percentage} style={{ position: 'relative', left: '40', zIndex: 1 }} />
                <CircularProgress color="secondary" variant="determinate" className="bottom-circle" value={100} />
              </Box>
              <Box
                sx={{
                  top: 0,
                  left: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  bottom: 0,
                  display: 'flex',
                  position: 'absolute',
                  right: 0,
                }}
              >
                <ProgressPercentTxt variant="caption">{`${itemTask.attributes.progress_percentage.toFixed(0)}%`}</ProgressPercentTxt>
              </Box>
            </Box>
            <DotSection>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-label="account of current user"
                onClick={(event) => this.changeOptionMenu(event, itemTask)}
                data-test-id="openOption"
                color="inherit"
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <MainMenu
                id="menu-appbar"
                anchorEl={this.state.editAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                elevation={0}
                open={Boolean(this.state.editAnchor)}
                onClose={() => this.setState({ editAnchor: null })}
                style={{ top: 2 }}
              >
                <MenuItem disabled>
                  <TaskAction>MARK AS   </TaskAction>
                </MenuItem>
                <Divider />
                <MenuItem data-test-id='in_progressTask' onClick={() => this.changeTaskStatus(item, 'in_progress')}>
                  <MenuText>In Progress</MenuText>
                </MenuItem>

                <MenuItem data-test-id='completedTask'
                  onClick={() => { this.changeTaskStatus(item, 'completed') }}
                >
                  <MenuText>Completed</MenuText>
                </MenuItem>
                {this.state.loginUser.attributes.role_id !== "student" && <>
                  <MenuItem disabled>
                    <TaskAction>TASK ACTIONS</TaskAction>
                  </MenuItem>
                  <MenuItem data-test-id='editAllocateTask' onClick={() => this.handleEditSelect(itemTask)}>
                    <img height={18} width={18} src={editButton} style={{ marginRight: 10 }} />
                    <Typography style={{ fontSize: 14, color: '#475569' }}>{configJSON.editTask}</Typography>
                  </MenuItem>

                  <MenuItem data-test-id='deleteAllocateTask'
                    onClick={() => { this.deleteTaskList(item.attributes.id) }}
                  >
                    <img height={18} width={18} src={deleteIcon} style={{ marginRight: 10 }} />
                    <Typography style={{ fontSize: 14, color: '#DC2626' }}>{configJSON.deleteTask}</Typography>
                  </MenuItem>
                </>}
              </MainMenu>
            </DotSection>
          </div>
        </CardHeader>
        <CardBody>
          {itemTask.attributes.student_task_lists?.slice(0, 6)?.map((item: IStudentTaskList, index: number) => <CheckLine>
            <TermsChecked
              data-test-id="subTaskCheck"
              checked={item.attributes.is_completed}
              onChange={(event) => this.checkSubtask(event, item.attributes.id)}
            />
            <CheckText>{item.attributes.task_list.attributes.name}</CheckText>
          </CheckLine>)}
          <Accordion expanded={itemTask.attributes.is_open}>
            {itemTask.attributes.is_open &&
              <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                  {itemTask.attributes.student_task_lists?.slice(6).map((item: IStudentTaskList) => <CheckLine>
                    <TermsChecked
                      data-test-id="subTaskCheck"
                      checked={item.attributes.is_completed}
                      onChange={(event) => this.checkSubtask(event, item.attributes.id)}
                    /><CheckText>{item.attributes.task_list.attributes.name}</CheckText>
                  </CheckLine>)}
                </div>
              </AccordionDetails>
            }
            <AccordionSummary>
              <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt="Remy Sharp" src={profile.attributes.image} />
                  <div style={{ marginLeft: '10px' }}>
                    <NameField style={{ fontSize: '11.51px' }}>{profile.attributes.preferred_name}</NameField>
                    <DesignationField style={{ fontSize: '12px' }}>{profile.attributes.role_id}</DesignationField>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                  {this.showMoreDetailsIcon(item)}
                  <div
                    data-test-id="expandAccordion"
                    onClick={() => this.handleAccordion(itemTask)}
                  >
                    {itemTask.attributes.is_open ? <ExpandLess /> : <ExpandMore />}
                  </div>
                </div>
              </div>
            </AccordionSummary>
          </Accordion>
        </CardBody>
      </CardSection >
    )
  }

  showMoreDetailsIcon = (item: ITask) => {
    let week = this.weekCalculate(String(item.attributes.expiration_date))
    if (week < 1) {
      return <AssignedLabel>{moment.utc(item.attributes.expiration_date).format('MM/DD/YYYY h:mm A')}</AssignedLabel>
    } else if (week > 1 && week < 2) {
      return <InProgressLabel>{moment.utc(item.attributes.expiration_date).format('MM/DD/YYYY h:mm A')}</InProgressLabel>
    } else {
      return <CompletedLabel>{moment.utc(item.attributes.expiration_date).format('MM/DD/YYYY h:mm A')}</CompletedLabel>
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.blackTheme ? darkTheme : lightTheme}>
        <HamburgerMenu
          navigation={this.props.navigation}
          handleMenu={this.toggleSideMenu}
          open={this.state.sideMenu}
          themeDark={this.state.blackTheme}
          handleTheme={this.themeChanges}
        />
        <OuterContainer
          style={{
            paddingLeft: this.state.sideMenu ? 273 : 140,
            paddingRight: 48,
            transition: 'all 300ms linear'
          }}
        >
          <BodyHeader>
            <div>
              <TaskListTitle>Assigned Tasks</TaskListTitle>
              <TaskListSubTitle>here where you can track all your assigned tasks</TaskListSubTitle>
            </div>
            {this.state.loginUser.attributes.role_id !== "student" && <AssignButton
              data-test-id="btnAllocateTask"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={this.showAddModal}
            >
              Allocate Task
            </AssignButton>}
          </BodyHeader>
          <Grid container>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='AssignedTask'
                  variant="text"
                  onClick={() => this.changeLeftButtonStatus(true, false, 'assigned')}
                  className={(this.state.taskStatus === "assigned") ? "selectedButton" : ""}
                >
                  Assigned
                </Button>
                <Button
                  data-test-id='importantTask'
                  variant="text"
                  onClick={() => this.changeLeftButtonStatus(false, true, 'important')}
                  className={(this.state.taskStatus === "important") ? "selectedButton" : ""}
                >
                  Important
                </Button>
              </ButtonGroup>
              <InnerSection className="scrollDiv">
                {this.state.taskLists?.map((item) => (
                  this.renderCardSection(item, item.attributes.profile)
                ))}
              </InnerSection>
            </LeftGrid>
            <LeftGrid item xs={"auto"}>
              <ButtonGroup>
                <Button
                  data-test-id='inProgressTask'
                  variant="text"
                  onClick={() => this.changeRightButtonStatus(true, false, 'in_progress')}
                  className={(this.state.taskStatus1 === "in_progress") ? "selectedButton" : ""}
                >
                  In Progress
                </Button>
                <Button
                  data-test-id='completedTask'
                  variant="text"
                  onClick={() => this.changeRightButtonStatus(false, true, 'completed')}
                  className={(this.state.taskStatus1 === "completed") ? "selectedButton" : ""}
                >
                  Completed
                </Button>
              </ButtonGroup>
              <InnerSection className="scrollDiv" >
                {this.state.taskLists2?.map((item) => (
                  this.renderCardSection(item, item.attributes.profile)
                ))}
              </InnerSection>
            </LeftGrid>
          </Grid>
          {this.AddTaskModal()}
          {this.allocateTypeModal()}
          {this.specificCompaniesModal()}
          <ToastContainer
            containerId='B'
            style={{ width: "463px", height: "42px" }}
            theme={this.state.blackTheme ? "dark" : "light"}
            closeButton={false}
          />
        </OuterContainer>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TaskListTitle = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 500,
  color: theme.palette.info.main,
}))

const TaskListSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '14px',
  fontSize: '14px',
  letterSpacing: '0.41px',
  marginTop: "10px",
  color: theme.palette.info.main,
}))

const BodyHeader = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  margin: '10px',
  display: 'flex',
  height: "100px",
  background: theme.palette.action.focus,
  marginBottom: '15px',
  borderRadius: "18px",
  padding: '20px',
  '& .MuiButton-root:hover': {
    backgroundColor: theme.palette.info.contrastText
  }
}))

const CardSection = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  borderRadius: "6px",
  width: "96%",
  borderLeft: `4px solid ${theme.palette.action.selected}`,
  padding: '8px 12px 8px 16px',
  background: theme.palette.background.default,
  marginBottom: "10px",
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0
  },
  '& .MuiPaper-root': {
    background: theme.palette.background.default,
  },
  '& .MuiAccordionDetails-root': {
    padding: 0
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: "transparent"
  },
  '& .MuiPaper-elevation1': {
    boxShadow: 'none',
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  },
  '& .MuiAccordionSummary-root': {
    padding: 0
  }
}))

const CardHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  "& .MuiCircularProgress-colorPrimary": {
    color: theme.palette.info.contrastText
  },
  "& .MuiCircularProgress-colorSecondary": {
    color: theme.palette.background.paper
  },
}))

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.secondary.main
}))

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: '14px',
  color: theme.palette.info.light
}))

const DueDateTxt = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: '14.8px',
  color: theme.palette.secondary.light
}))

const CardBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column'
}))

const LeftGrid = styled(Grid)(({ theme }) => ({
  marginLeft: "10px",
  background: theme.palette.action.focus,
  width: "48%",
  borderRadius: "18px",
  padding: '20px',
  height: '80vh',
  "& .MuiButtonGroup-root": {
    borderRadius: '50px',
    background: theme.palette.background.default,
    width: "100%",
    marginBottom: '10px'
  },
  "& .MuiButtonGroup-grouped": {
    fontWeight: 500,
    textTransform: 'capitalize',
    lineHeight: '22px',
    fontsize: '14px',
    borderRadius: '30px',
    color: theme.palette.info.light,
    margin: '5px',
    padding: '5px',
    width: "100%"
  },
  "& .selectedButton": {
    background: theme.palette.background.paper,
    color: theme.palette.info.contrastText,
  },
  "& .recharts-text": {
    fontFamily: 'Rubik',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '11.85px',
    color: theme.palette.info.main
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
    background: theme.palette.background.default
  },
  '& .MuiAccordion-root::before': {
    backgroundColor: 'transparent'
  },
  '& .recharts-cartesian-axis-line': {
    display: 'none'
  },
  "& .scrollDiv": {
    overflowY: 'scroll'
  },
  "& .scrollDiv::-webkit-scrollbar-track": {
    border: `1px solid ${theme.palette.info.dark}`,
    borderRadius: '30px 30px 90px 30px',
    background: 'transparent',
  },
  "& .scrollDiv::-webkit-scrollbar": {
    borderRadius: '30px 30px 90px 30px',
    width: '13px',
  },
  "& .scrollDiv::-webkit-scrollbar-thumb": {
    height: '43px',
    width: '13px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #94A3B8',
    borderRadius: '30px 30px 90px 30px',
  },
}))

const InnerSection = styled(Box)(({ theme }) => ({
  height: '66vh',
}))

export const DateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '19px',
  marginLeft: "10px"
}))

const AddNewButton = styled(Button)(({ theme }) => ({
  height: '22px',
  width: "100px",
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  float: "right",
  color: theme.palette.info.contrastText
}))

const TaskAction = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: '18px',
  color: theme.palette.info.light
}))

export const AllocateTypeImage = styled('img')(({ theme }) => ({
  width: "16px",
  height: "16px",
  marginRight: "10px"
}))

export const AllocateTypeText = styled('span')(({ theme }) => ({
  fotSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: theme.palette.info.main
}))

const MainMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.default,
    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 4px 0px`,
    borderRadius: '18px',
    width: '200px',
    border: `1px solid ${theme.palette.action.focus}`
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.warning.main
  }
}))

const AssignButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  width: '100%',
  maxWidth: "170px",
  height: '44px',
  gap: '8px',
  borderRadius: '30px',
  padding: '10px 12px',
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFF',
  background: theme.palette.info.contrastText
}))

const ProgressPercentTxt = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: '8px',
  color: theme.palette.secondary.main
}))

export const CountChar = styled('span')(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  color: theme.palette.text.primary,
  textAlign: 'right',
  float: 'right',
  width: '100%'
}))
// Customizable Area End
