import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Typography,
  Toolbar,
  InputBase,
  IconButton,
  Avatar,
  List,
  ListItemText,
  ListItemIcon,
  Chip as MuiChip,
  styled,
  Divider,
  CircularProgress,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { funnel, searchAgain, search, delete_warning, bg, darkBg} from "./assets";
import moment from "moment";
import { configJSON } from './AdvancedSearchController'
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderInstructorBlock = (item: any) => {
    if (this.state.selectedFilters.includes("instructor") && item.instructor) {
      return (
        <div style={{ display: "flex", alignItems: "baseline", cursor: "pointer" }}>
          <Divider orientation="vertical" flexItem style={{ marginLeft: 14, marginRight: 14 }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <GroupHeadingTypography variant="body2">Instructor Name</GroupHeadingTypography>
            <GroupNameTypography variant="body2">{item.instructor}</GroupNameTypography>
          </div>
        </div>
      );
    }
    return null;
  };

  renderCompanyBlock = (item: any) => {
    if (this.state.selectedFilters.includes("company") && item.companyName) {
      return (
        <div style={{ display: "flex", alignItems: "baseline", cursor: "pointer" }}>
          <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <GroupHeadingTypography variant="body2">Company Name</GroupHeadingTypography>
            <GroupNameTypography variant="body2">{item.companyName}</GroupNameTypography>
          </div>
        </div>
      );
    }
    return null;
  };

  renderStatusBlock = (filter: string, item: any) => {
    if (this.state.selectedFilters.includes(filter) && item.status) {
      return (
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}>

          <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
          {item.status === "published" && <Completed data-test-id="completed">{this.formatStatus(item.status)}</Completed>}
          {item.status === "draft" && <Inprogress data-test-id="inprogressSavetest">{this.formatStatus(item.status)}</Inprogress>}
          {item.status === "archived" && <Inprogress data-test-id="inprogressSavetest">{this.formatStatus(item.status)}</Inprogress>}
          {item.status === "completed" && <Completed data-test-id="inprogressSavetest">{this.formatStatus(item.status)}</Completed>}
          {item.status === "in_progress" && <Inprogress data-test-id="inprogressSavetest">{this.formatStatus(item.status)}</Inprogress>}
        </div>
      );
    }
    return null;
  };


  AdvancedFilters = () => (
    <div data-test-id="advanced-filters" style={{ zIndex: 100 }}>
      <Dropdown>
        <ArrowIcone />
        {this.renderHeader1()}

        <Divider />
        {this.state.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
            <CircularProgress data-test-id="loading-spinner" />
          </div>
        ) : (
          <List data-test-id="search-history-list" style={{ maxHeight: "230px", overflow: "hidden" }}>
            {this.state.searchHistory.map((item, index) => (
                <div key={index} onClick={()=>this.handleAddToSearchHistory(item.id)} data-test-id="addSearch">
                <div style={{ display: "flex", alignItems: "center" }} data-test-id={`search-history-item-${index}`}>
                  <ListItemIcon style={{ marginRight: "6px", minWidth: "0px" }}>
                    {item.searchHistoryIcon ? <img src={searchAgain} alt="searchAgain" style={{ width: "16px", height: "16px" }} /> : <img src={search} alt="search" style={{ width: "16px", height: "16px" }} />}
                  </ListItemIcon>
                  <ListItemText style={{ display: "flex", marginRight: "14px" }}
                    primary={
                        <div style={{ display: "flex", cursor:"pointer", flexDirection: "column", marginRight: "10px", alignItems: "baseline", minWidth: "150px" }}>
                        <CourseHeadingTypography variant="body2">
                          Course Name
                        </CourseHeadingTypography>
                        <CourseNameTypography
                          variant="body2"
                        >
                          {item.courseName}
                        </CourseNameTypography>
                      </div>
                    }
                    secondary={
                      <>
                        {item.groupName && (
                          <div style={{ display: "flex", alignItems: "baseline", cursor:"pointer" }}>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 14, marginRight: 14 }} />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <GroupHeadingTypography variant="body2">
                                Group Name
                              </GroupHeadingTypography>
                              <GroupNameTypography
                                variant="body2"
                              >
                                {item.groupName}
                              </GroupNameTypography>
                            </div>
                          </div>
                        )}
                        {this.state.selectedFilters.includes("creation_date") && (
                          <div style={{ display: "flex", alignItems: "baseline", cursor:"pointer" }}>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 14, marginRight: 14 }} />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <GroupHeadingTypography variant="body2">
                                Creation Date
                              </GroupHeadingTypography>
                              <GroupNameTypography
                                variant="body2"
                              >
                                {moment(item.createdAt).format(configJSON.dateFormat)}
                              </GroupNameTypography>
                            </div>
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  {index < this.state.searchHistory.length - 1 && <Divider />}
                </div>
              </div>
            ))}
          </List>
        )}
          <SeeAllResults onClick={() => {this.goToFilters("Filteritems"); this.handleRedirectFilterPage()}} data-test-id="see-all-results-button">
          <StyledLine />
          <SeeResultTypo variant="body2">See all results</SeeResultTypo>
          <ChevronRightIcon fontSize="small" style={{ color: "#475569" }} />
        </SeeAllResults>

      </Dropdown>
    </div>
  );

  SaveFilters = () => (
    <div style={{ position: "absolute", top: "60px", zIndex: 100 }} data-test-id="save-filters">
      <Dropdown data-test-id="dropdown">
        <ArrowIcone />
        <div data-test-id="dropdown-content">
          <Header data-test-id="header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextColor variant="subtitle1" style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                {this.renderFilterImage()}
                <FilterTypo onClick={this.handleFilters} data-test-id="filters-button">Filters</FilterTypo>
              </TextColor>
              <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
              <TextColor variant="subtitle1" style={{ marginLeft: 8, fontSize: "12px" }}>
                <SearchTypo onClick={this.handleHistory} data-test-id="history-button">Search History</SearchTypo>
              </TextColor>
            </div>
            <Divider />
            <StyledLine />
            {this.state.selectedFilters.length !== 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextColor variant="subtitle1" style={{ marginLeft: 8, color: "#DC2626", marginRight: 8, fontSize: "12px",fontWeight:500 }}>
                  <div onClick={this.handleReset} data-test-id="reset-filters-button">Reset Filters</div>
                </TextColor>
                <StyledIconButton size="small" data-test-id="close-button">
                  <CloseIcon fontSize="small" style={{ color: "#DC2626" }} onClick={this.handleClose} />
                </StyledIconButton>
              </div>
            )}
          </Header>
          {this.state.searchHistory.length>0?
            <React.Fragment>
              <Divider />
              <List style={{ maxHeight: "220px", overflowY: "auto" }} data-test-id="save-history-filter">
                {this.state.searchHistory.map((item, index) => (
              <div data-test-id={`search-history-item-${index}`} key={index} onClick={()=>this.handleAddToSearchHistory(item.id)}>
                <div style={{ padding: "5px 0", display: "flex", alignItems: "center",  }}>
                      <ListItemIcon style={{ minWidth: 0 }}>
                    {!item.searchHistoryIcon ? <img src={search} alt="search" style={{width:"16px",height:"16px"}} /> : <img src={searchAgain} style={{width:"16px",height:"16px"}} alt="searchAgain" />}
                      </ListItemIcon>
                      <div style={{ display: "flex", width: "100%", marginRight: "15px", }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                      <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <div style={{ cursor:"pointer", display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                              <Typography style={{ fontSize: "8px", width: "100px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} variant="body2" component="span">
                                Course Name
                              </Typography>
                              <Typography
                                style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8, width: "100px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                                variant="body2"
                                component="span"
                              >
                                {item.courseName}
                              </Typography>
                            </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline",cursor:"pointer" }}>
                              {item.groupName && (
                                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                              <Divider flexItem orientation="vertical"  style={{ marginLeft: 8, marginRight: 8 }} />
                                  <div style={{ display: "flex" }}>
                                    <div>
                                  <div style={{ marginRight: 10 , display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                                    <Typography component="span" variant="body2"  style={{ fontSize: "8px", width: "83px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} data-test-id="groupInstructor">
                                          Group Name
                                        </Typography>
                                        <Typography
                                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8, width: "83px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                                          variant="body2"
                                          component="span"
                                        >
                                          {item.groupName}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {this.renderInstructorBlock(item)}
                              {this.renderCompanyBlock(item)}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                              {this.state.selectedFilters.includes("creation_date") && (
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between",cursor:"pointer" }}>
                              <Divider flexItem orientation="vertical"  style={{ marginLeft: 8, marginRight: 8 }} />
                                  <div style={{ display: "flex" }}>
                                    <div>
                                  <div style={{ marginRight: 10 , display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                                    <Typography component="span" variant="body2"  style={{ fontSize: "8px", width: "83px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} data-test-id="groupInstructor">
                                          Creation Date
                                        </Typography>
                                        <Typography
                                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: 8, width: "83px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                                          variant="body2"
                                          component="span"
                                        >
                                          {moment(item.createdAt).format(configJSON.dateFormat)}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            </div>
                            {this.renderStatusBlock("draft", item)}
                            {this.renderStatusBlock("archived", item)}
                            {this.renderStatusBlock("published", item)}
                            {this.renderStatusBlock("completed", item)}
                            {this.renderStatusBlock("in_progress", item)}
                          </div>
                          <Typography style={{ fontSize: "8px" }}>{item.sort}</Typography>
                        </div>

                      </div>
                    </div>
                    <div>
                      {index < this.state.searchHistory.length - 1 && <Divider />}
                    </div>
                  </div>
                ))}
              </List>
          <SeeAllResults onClick={() => {this.goToFilters("Filteritems");this.handleRedirectFilterPage()}} data-test-id="see-all-results">
                <StyledLine />
                <TextColor variant="body2">See all results</TextColor>
                <ChevronRightIcon fontSize="small" style={{ color: "#475569" }} />
              </SeeAllResults>
            </React.Fragment>
            :
            <NoSearchContent data-test-id="empty-content" darkMode={this.props.darkMode}>
              <NoSearchTypography data-test-id="empty-no-search">
                "No Search Found"
              </NoSearchTypography>
              <NoSearchDesc data-test-id="empty-explore-text">
                Start exploring and your searches will appear here!
              </NoSearchDesc>
            </NoSearchContent>
          }
        </div>
      </Dropdown>
    </div>
  );
  renderFilters = () => (
    <div style={{ position: "absolute", top: "60px", zIndex: 100 }} data-test-id="filters-container">
      <Dropdown1 data-test-id="dropdown1" >
        <ArrowIcone />
        <Header data-test-id="filters-header">
          <ChevronLeftIcon
            fontSize="small"
            style={{ color: "#1A469C" }}
            onClick={this.handleFiltersBack}
            data-test-id="back-button"
          />
          <Typography style={{ fontSize: "16px", fontWeight: 500 }} data-test-id="filter-title">
            Filter by
          </Typography>
          <TextColor
            variant="subtitle1"
            style={{ marginLeft: 8, fontSize: "12px", fontWeight:500,cursor:"pointer" }}
            onClick={this.handleRedirectFilterPage}
            data-test-id="all-filters-button"
          >
            All Filters
          </TextColor>
        </Header>
        <div>
          <Wrapper data-test-id="course-wrapper">
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }} data-test-id="course-title">
              Course
            </Typography>
            <div data-test-id="course-filters">
              {this.state.allFilters.course.map((filter, index) => (
                <StyledChip
                  data-test-id="course-chip"
                  key={index}
                  label={filter}
                  className={this.state.selectedFilters.includes(filter) ? "selected" : ""}
                  onClick={() => {this.handleChipClick(filter);this.handleSelectedFilters("cource",filter)}}
                  deleteIcon={<CloseIcon fontSize="small" style={{color:"#F8FAFC"}} />}
                  onDelete={
                    this.state.selectedFilters.includes(filter)
                      ? () => {this.handleRemoveFilter("course", filter);this.handleSelectedFilters("cource",filter)}
                      : undefined
                  }

                />
              ))}
            </div>
          </Wrapper>
          <Wrapper data-test-id="status-wrapper">
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }} data-test-id="status-title">
              Status
            </Typography>
            <div data-test-id="status-filters">
              {this.state.allFilters.status.map((filter, index) => (
                <StyledChip data-test-id="status-chip"
                  key={index}
                  label={this.formatStatusFilter(filter)}
                  disabled={!this.state.allSelectedFilters.status.includes(filter) && this.state.allSelectedFilters.status.length!==0}
                  onClick={() => {this.handleChipClick(filter);this.handleSelectedFilters("status",filter)}}
                  className={this.state.selectedFilters.includes(filter) ? "selected" : ""}
                  deleteIcon={<CloseIcon fontSize="small" style={{color:"#F8FAFC"}} />}
                  onDelete={this.state.selectedFilters.includes(filter)? () => {this.handleRemoveFilter("status", filter);this.handleSelectedFilters("status",filter)} : undefined}
                />
              ))}
            </div>
          </Wrapper>
          {this.state.allFilters.sort.length > 0 && 
          <Wrapper data-test-id="sortby-wrapper">
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }} data-test-id="sortby-title">
              Sort by
            </Typography>
            <div data-test-id="sortby-filters">
              {this.state.allFilters.sort.map((filter, index) => (
                <StyledChip
                  key={index}
                  label={this.formatStatusFilter(filter)}
                  disabled={!this.state.allSelectedFilters.sort.includes(filter) && this.state.allSelectedFilters.sort.length!==0}
                  onClick={() => {this.handleChipClick(filter);this.handleSelectedFilters("sort",filter)}}
                  className={this.state.selectedFilters.includes(filter) ? "selected" : ""}
                  deleteIcon={<CloseIcon fontSize="small" style={{color:"#F8FAFC"}} />}
                  onDelete={this.state.selectedFilters.includes(filter)? () => {this.handleRemoveFilter("sort", filter);this.handleSelectedFilters("sort",filter)} : undefined}
                  data-test-id="sortby-chip"
                />
              ))}
            </div>
          </Wrapper>
          }
        </div>

        <Footer data-test-id="filters-footer">
          <BackButton onClick={this.handleSaveFilters} data-test-id="footer-back-button">Back</BackButton>
          <SaveButton onClick={this.handleSaveFilters} data-test-id="footer-save-button">Save Filters</SaveButton>
        </Footer>

      </Dropdown1>
    </div>
  )

  renderEmpty = (isHistory:boolean) => (
    <Dropdown2 style={{ height: "300px", zIndex: 100 }} data-test-id="empty-dropdown">
      <ArrowIcone />
      {isHistory ? <Header data-test-id="empty-header">
        <ChevronLeftIcon
          fontSize="small"
          style={{ color: "#1A469C" }}
          onClick={this.openHistory}
          data-test-id="empty-back-button"
        />
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }} data-test-id="empty-title">
          Search History
        </Typography>
        <StyledIconButton size="small" data-test-id="empty-close-button">
          <CloseIcon
            fontSize="small"
            style={{ color: "#DC2626" }}
            onClick={this.handleClose}
            data-test-id="empty-close-icon"
          />
        </StyledIconButton>
      </Header>

        : this.renderHeader1()}

      <NoSearchContent data-test-id="empty-content" darkMode={this.props.darkMode}>
        <NoSearchTypography data-test-id="empty-no-search">
         {isHistory? `"No Search History"`: `"No Search Found"`}
        </NoSearchTypography>
        <NoSearchDesc data-test-id="empty-explore-text">
          Start exploring and your searches will appear here!
        </NoSearchDesc>
      </NoSearchContent>

      {isHistory&& <Footer data-test-id="empty-footer">
        <BackButton
          onClick={this.openHistory}
          style={{ width: "100%" }}
          data-test-id="empty-back-button-footer"
        >
          Back
        </BackButton>
      </Footer>}
    </Dropdown2>
  )

  renderHistory = () => (
    <div style={{ position: "relative", zIndex: 100 }} data-test-id="search-history">
      <Dropdown2 data-test-id="history-dropdown">
        <Header data-test-id="history-header">
          <ChevronLeftIcon
            fontSize="small"
            style={{ color: "#1A469C" }}
            onClick={this.handleFiltersBack}
            data-test-id="history-back-button"
          />
          <Typography style={{ fontSize: "16px", fontWeight: "bold" }} data-test-id="history-title">
            Search History
          </Typography>
          <StyledIconButton size="small" data-test-id="history-close-button">
            <CloseIcon
              fontSize="small"
              style={{ color: "#DC2626" }}
              onClick={this.handleFiltersBack}
              data-test-id="history-close-icon"
            />
          </StyledIconButton>
        </Header>
        <div data-test-id="history-content">
          {this.state.isLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }} data-test-id="history-loading">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <List data-test-id="history-list" style={{ maxHeight: "220px", overflowY: "auto" }}>
                {this.state.searchAllHistory.map((item, index) => (
                  <div key={index} data-test-id={`history-item-${index}`}>
                    <section style={{ display: "flex", alignItems: "center", padding: "5px 0" }}>
                      <ListItemIcon style={{ minWidth: 0,marginRight:"6px" }} data-test-id="history-item-icon">
                        <img src={searchAgain} alt="searchAgain" style={{width:"16px",height:"16px"}} /> 
                      </ListItemIcon>
                      <section style={{ display: "flex", marginRight: "15px", width: "100%" }}>
                        <section style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                          <section style={{ display: "flex", justifyContent: "space-between", minWidth: "150px" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline", cursor:"pointer" }}>
                              <CourseHeadingTypography variant="body2">
                                Course Name
                              </CourseHeadingTypography>
                              <CourseNameTypography
                                variant="body2"
                                data-test-id="CoursenameTest"
                              >
                                {item.courseName}
                              </CourseNameTypography>
                            </div>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 14, marginRight: 14 }} />
                            <section style={{ display: "flex", flexDirection: "column", alignItems: "baseline", }}>
                              {item.groupName && (
                                <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                  <section style={{ display: "flex" }}>
                                    <section>
                                      <section style={{ display: "flex", flexDirection: "column", alignItems: "baseline", marginRight: 10, cursor:"pointer" }}>
                                        <GroupHeadingTypography variant="body2" data-test-id="groupInstructor">
                                          Group Name
                                        </GroupHeadingTypography>
                                        <GroupNameTypography
                                          variant="body2"
                                        >
                                          {item.groupName}
                                        </GroupNameTypography>
                                      </section>
                                    </section>
                                  </section>
                                </section>
                              )}
                            </section>
                            <Divider orientation="vertical" flexItem style={{ marginLeft: 14, marginRight: 14 }} />
                            { item.status === "completed" ? 
                            (<Completed data-test-id="completed">COMPLETED</Completed>):
                              (<Inprogress data-test-id="inprogresstest">{this.formatStatus(item.status)}</Inprogress>)}
                          </section>
                          <Typography style={{ fontSize: "8px" }}>{item.sort}</Typography>
                        </section>
                      </section>
                    </section>
                    <div>
                      {index < this.state.searchAllHistory.length - 1 && <Divider data-test-id="history-divider" />}
                    </div>
                  </div>
                ))}
              </List>
            </div>
          )}
        </div>
        <Footer data-test-id="history-footer">
          <BackButton onClick={this.handleFiltersBack} data-test-id="history-back-button-footer">Back</BackButton>
          <SaveButton onClick={this.handleModalOpen} data-test-id="history-delete-all-button">Delete All</SaveButton>
        </Footer>
      </Dropdown2>

      {this.state.modalOpen &&
        <Popup data-test-id="history-popup">
          <Box style={{margin:"29.15px"}}>
            <PopupHeaderBox data-test-id="popup-header">
            <div style={{  width: "157px",height:"23px",gap: "1.21px",opacity: "0px" }}>
                <DeleteTypography data-test-id="popup-warning-title">Are you sure?</DeleteTypography>
              </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "171.99px",
                height: "16px",
                gap: "13.97px",
                opacity: "0px" }} data-test-id="popup-divider">
                <StyledIconButton size="small" data-test-id="history-close-button">
                  <CloseIcon
                    fontSize="small"
                      style={{ color: "#DC2626",width:"14.57px",height:"14.57px" }}
                    onClick={this.handleModalClose}
                    data-test-id="history-close-icon"
                  />
                </StyledIconButton>
              </div>
            </PopupHeaderBox>

            <TextColor style={{ display: "flex", alignItems: "center", padding: 0, margin: "10px 0" }} data-test-id="popup-warning-text">
            <img src={delete_warning} alt="delete_warning"/>
              <DeleteDetailTypography>The information in your search history will be permanently removed.</DeleteDetailTypography>
            </TextColor>

            <Footer data-test-id="popup-footer">
              <CancelButton onClick={this.handleModalClose} data-test-id="popup-cancel-button">
                Cancel
              </CancelButton>
              <DeleteButton onClick={this.handleDelete} data-test-id="popup-delete-button">
                Confirm
              </DeleteButton>
            </Footer>
          </Box>
        </Popup>
      }
    </div>
  )

  renderHistory1 = () => (
    <div style={{ position: "absolute", top: "60px", zIndex: 100 }} data-test-id="history1-container">
      <ArrowIcone style={{left: '49px'}}/>
      {this.state.searchAllHistory.length === 0 && this.state.isLoading === false ? (
        this.renderEmpty(true)
      ) : (
        this.renderHistory()
      )}
    </div>
  )

  renderFilters1 = () => (
    <div style={{ position: "absolute", top: "60px", zIndex: 100 }} data-test-id="filters1-container">

      {this.state.searchHistory.length === 0 && this.state.isLoading === false ? (
        this.renderEmpty(false)
      ) : (
        this.AdvancedFilters()
      )}
    </div>
  )

  renderHeader = () => (
    <Search data-test-id="search-container">
      <SearchIconWrapper data-test-id="search-icon-wrapper">
        <SearchIcon data-test-id="search-icon" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={(event) => this.handleInputChange(event)}
        onClick={this.openHistory}
        data-test-id="search-input"
        value={this.state.searchQuery}
      />
    </Search>
  )

  renderFilterImage=()=>(<img src={funnel} alt="funnel" style={{ marginRight: 8, width: 16 }} />)
  renderHeader1=()=>(
    <Header>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextColor variant="subtitle1" style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
          {this.renderFilterImage()}
          <FilterTypo onClick={this.handleFilters} data-test-id="filters-button">Filters</FilterTypo>
        </TextColor>
        <Divider orientation="vertical" flexItem style={{ marginLeft: 8, marginRight: 8 }} />
        <TextColor
          variant="subtitle1"
          style={{ marginLeft: 8, fontSize: "12px" }}
        >
          <SearchTypo onClick={this.handleHistory} data-test-id="search-history-button">Search History</SearchTypo>
        </TextColor>
      </div>
      <Divider />
      <StyledLine />
      <StyledIconButton size="small" >
        <CloseIcon fontSize="small" style={{ color: "#DC2626" }} onClick={this.handleClose} data-test-id="close-button" />
      </StyledIconButton>
    </Header>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.props.darkMode ? darkTheme : lightTheme} data-test-id="theme-provider">
        <ContainerBody data-test-id="container-body">
          {this.renderHeader()}
          {(this.state.history &&!this.state.openSaveFilters)&& this.renderHistory1()}
          {(this.state.isOpen && !this.state.openSaveFilters && !this.state.filters) &&this.renderFilters1()}
          {this.state.filters && this.renderFilters()}
          {this.state.openSaveFilters && this.SaveFilters()}
        </ContainerBody>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      light: '#F8FAFC',
      dark: '#FAFAF9',
      contrastText: '#DC2626'
    },
    secondary: {
      dark: '#808080',
      light: '#848484',
      main: '#000000',
      contrastText: "#94A3B8"
    },
    background: {
      paper: '#EDF1F6',
      default: '#FFFFFF'
    },
    text: {
      hint: '#E2E8F0',
      secondary: '#43536A',
      primary: '#0F172A',
      disabled: "#F4F4F5"
    },
    action: {
      focus: '#F9F9F9',
      selected: '#1A469C',
      active: '#F1F5F9',
      disabled: '#D7D7D7',
      hover: "#3F3F46",
      disabledBackground: "#27272A"
    },
    info: {
      light: '#475569',
      main: "#0F172A",
      contrastText: '#1A469C',
      dark: '#CBD5E1',
    },
    warning: {
      main: '#E8EDF5',
      light: "#059669",
      dark: '#D97706',
      contrastText: "#DDE1E6"
    },
    error: {
      main: "#FEE2E2",
      light: "#FEF3C7",
      dark: "#D1FAE5",
      contrastText: '#6683BD'
    },
    success: {
      main: "#475569"
    },
  },
  typography: FontStyle
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#161616',
      light: '#0F172A',
      dark: '#121212',
      contrastText: '#F87171'
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#18212E',
      light: '#99A7BB',
      contrastText: "#94A3B8"
    },
    background: {
      default: "#0D0D0D",
      paper: '#121B29'
    },
    text: {
      primary: '#D5DDF0',
      secondary: '#C4CFE1',
      hint: '#18212E',
      disabled: '#F7FBF7',
    },
    action: {
      active: '#424242',
      selected: 'rgb(112, 161, 255)',
      focus: '#161616',
      disabled: '#D7D7D733',
      hover: "#393D42",
      disabledBackground: "#212529"
    },
    info: {
      main: "#D5DDF0",
      light: '#99A7BB',
      dark: '#273444',
      contrastText: '#70A1FF'
    },
    warning: {
      main: '#121B29',
      light: '#42EBB5',
      dark: "#F28407",
      contrastText: "#242A31"
    },
    error: {
      main: "#1C1C1C",
      light: "#514101",
      dark: "#074525",
      contrastText: "#4F6080"
    },
    success: {
      main: "#93A1B5"
    }
  },
  typography: FontStyle
});
const FilterTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  cursor:"pointer",
  color: theme.palette.action.selected
}))
const SearchTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  cursor:"pointer",
  color: theme.palette.action.selected
}))
const SeeResultTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.action.selected
}))
const StyledChip = styled(MuiChip)(({ theme }) => ({
  marginLeft: "10px",
  textTransform:"capitalize",
  cursor:"pointer",
  "&.selected": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main,
  },
  "&:not(.selected)": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.action.selected,
  },
  "& .MuiChip-deleteIcon": {
    display: "block",
  },
  "&.selected .MuiChip-deleteIcon": {
    display: "block",
    color: 'red',
  },
}));


const TextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.selected,
  cursor:"pointer"
}))

const Inprogress = styled(Typography)(({ theme }) => ({
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  fontFamily: "Rubik",
  fontSize: "9.03px",
  fontWeight: 500,
  lineHeight: "13.55px",
  textAlign: "center",
  width: "auto",
  whiteSpace:'nowrap',
  height: "18px",
  padding: "4.52px",
  gap: "7.53px",
  borderRadius: "28.6px",
  opacity: "0px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))
const Completed = styled(Typography)(({ theme }) => ({
  backgroundColor: '#D1FAE5',
  color: '#059669',
  fontFamily: "Rubik",
  fontSize: "9.03px",
  fontWeight: 500,
  lineHeight: "13.55px",
  textAlign: "center",
  width: "auto",
  height: "18px",
  padding: "4.52px",
  gap: "7.53px",
  borderRadius: "28.6px",
  opacity: "0px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))


const TextColor1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  cursor:"pointer"
}))

const NoSearchTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kallisto !important",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  letterSpacing: "-0.005em",
  textAlign: "left",
  color: theme.palette.text.primary,
  width: "352px",
  height: "25px",
  gap: "0px",
  opacity: "0px",
}))
const NoSearchDesc = styled(Typography)(({ theme }) => ({
  width: "362px",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "11.1px",
  fontWeight: 400,
  lineHeight: "15.54px",
  textAlign: "left",
  color: theme.palette.text.primary
}))

const DeleteDetailTypography = styled(Typography)(({ theme }) => ({
  width: "219.2px",
  height: "39px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10.93px",
  fontWeight: 500,
  lineHeight: "12.95px",
  textAlign: "left",
  color: theme.palette.text.primary,
  marginLeft:"14.57px"
}))
const CourseHeadingTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "7.09px",
  fontWeight: 500,
  lineHeight: "10.64px",
  textAlign: "left",
  color: theme.palette.info.light,
  cursor:"pointer"
}))
const CourseNameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "8.28px",
  fontWeight: 500,
  lineHeight: "10.64px",
  textAlign: "left",
  color: theme.palette.text.primary,
  cursor:"pointer"
}))
const GroupHeadingTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "7.33px",
  fontWeight: 500,
  lineHeight: "11px",
  textAlign: "left",
  color: theme.palette.info.light,
  cursor:"pointer",
  width: "83px", 
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}))
const GroupNameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "8.56px",
  fontWeight: 500,
  lineHeight: "11px",
  textAlign: "left",
  color: theme.palette.text.primary,
  cursor:"pointer",
  width: "83px", 
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}))
const DeleteTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "19.43px",
  fontWeight: 500,
  lineHeight: "23.03px",
  textAlign: "center",
  color: theme.palette.text.primary,
  width:"170px",
  cursor:"pointer"
}))
const SearchTypography = styled(Typography)(({ theme }) => ({
  width: "92px",
  height: "16px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "13.36px",
  fontWeight: 400,
  lineHeight: "15.83px",
  textAlign: "left",
  color: "#334155",
  cursor:"pointer"
}))

const StyledLine = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: "1px",
  margin: "0 8px",
  backgroundColor: "#D7D7D780",
}));

const ContainerBody = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ArrowIcone = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-6px',
  left: '10%',
  transform: 'rotate(45deg)', // Center adjustment
  width: '10px',
  height: '10px',
  zIndex:999,
  borderBottom:'none',
  borderRight:'none',
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`, 
}));

const StyledIconButton = styled(IconButton)({
  padding: 10,
});

const BoxContainer = styled(Box)({
  color: theme.palette.info.light,
})

const SearchIconWrapper = styled("div")({
  padding: "0 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "gray"
});

const Search = styled('div')(({ theme }) => ({
  position: "relative",
  height:"44px",
  borderRadius: "98px",
  backgroundColor: theme.palette.background.default,
  cursor:"pointer",
  "&:hover": {
    border: `2px solid ${theme.palette.action.selected}`,
  },
  marginRight: "16px",
  marginLeft: 0,
  width: "100%",
  "@media (min-width:600px)": {
    marginLeft: "24px",
    width: "auto",
  },
  color: "#8C8C8C",
  border: `1px solid ${theme.palette.info.dark}`,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: "50px",
  paddingTop:"5px",
  width: '100%',
  '&::placeholder': {
    color: theme.palette.primary.main
  },
}));
const DeleteButton = styled(Button)(({ theme }) => ({
  width: "193.09px",
  height: "26.72px",
  opacity: "0px",
  padding: "6.07px 9.72px 6.07px 9.72px",
  gap: "4.86px",
  borderRadius: "18.22px",
  background: "#1A469C",
  color: "#F8FAFC",
  cursor:"pointer",
  fontFamily: "Rubik",
  fontSize: "9.72px",
  fontWeight: 700,
  lineHeight: "14.57px",
  textAlign: "left",
  textTransform:"capitalize",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "9.72px",
    fontWeight: 700,
    lineHeight: "14.57px",
    textAlign: "left",
    width: "32px",
    height: "15px",
    gap: "0px",
    opacity: "0px",
    color: "#F8FAFC"
  },
  "&:hover": {
    background: "#1A469C",
    color: "#F8FAFC"
  }
}));
const CancelButton = styled(Button)(({ theme }) => ({
  width: "111.12px",
  height: "26.72px",
  padding: "6.07px 9.72px 6.07px 9.72px",
  gap: "4.86px",
  borderRadius: "18.22px",
  opacity: "0px",
  border: "0.61px solid  #1A469C",
  cursor:"pointer",
  textTransform:"capitalize",
  fontFamily: "Rubik",
  fontSize: "9.72px",
  fontWeight: 700,
  lineHeight: "14.57px",
  textAlign: "left",
  color: "#1A469C",
  "& .MuiButton-label":{
    width: "34px",
    height: "15px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "9.72px",
    fontWeight: 700,
    lineHeight: "14.57px",
    textAlign: "left",
    color: "#1A469C"
  },
}));
const SaveButton = styled(IconButton)({
  background: "#1A469C",
  border: "none",
  color: "#F8FAFC",
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  width: "80%",
  height: "32px",
  fontSize: "16px",
  fontWeight: "bold",
  "&:hover": {
    background: "#1A469C",
    color: "#F8FAFC"
  }
});

const BackButton = styled(IconButton)({
  color: "#1A469C",
  borderRadius: "30px",
  padding: "6px 10px 6px 10px",
  cursor: "pointer",
  border: "1px solid #1A469C",
  width: "89px",
  height: "32px",
  fontSize: "16px",
  marginRight: "10px",
  fontWeight: 700,
});

const Dropdown = styled(Paper)(({ theme }) => ({
  width: 500,
  maxHeight:350,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  overflow:"hidden"
}));

const Appbar = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const Dropdown1 = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 500,
  maxHeight:350,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  overflow:"hidden"
}));

const Dropdown2 = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: 500,
  maxHeight:350,
  borderRadius: "18px",
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  overflow:"hidden"
}));

const Content = styled('div')(({ theme }) => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top 10px',
  textAlign: "left",
  padding: "50px"
}));
const NoSearchContent = styled(Box)(({ theme, darkMode}: { theme: any; darkMode: any }) => ({
  backgroundImage: darkMode
    ? `url(${darkBg})`
    : `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 50px top -30px',
  backgroundSize: "126px 137px",
  width: "418px",
  height: "116.88px",
  gap: "0px",
  borderRadius: "4.97px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-evenly",
  alignItems:"left",
  margin:"30px",
}));
const PopupHeaderBox = styled(Box)(({ theme }) => ({
  width: "312.71px",
  height: "23px",
  gap: "100px",
  opacity: "0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
const Popup = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: 50,
  left: 60,
  width: "371px",
  height: "216.09px",
  gap: "14.57px",
  borderRadius: "18.22px",
  opacity: "0px",
  background: theme.palette.background.paper,
  border: "0.61px solid  #FFFFFF",
  boxShadow: "0px 25px 50px 0px #00000017"
}));

const Header = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const Footer = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "15px 0"
}));

const SeeAllResults = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: theme.spacing(1),
  color: theme.palette.primary.main,
  cursor: "pointer",
}));
// Customizable Area End
