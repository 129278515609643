import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/styles";
import { Paper, Dialog, DialogContent, IconButton, Checkbox, Button, Typography, FormControlLabel, Radio, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ScoringController, { Props } from "./ScoringController";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import {imgProductCard, ReDoButton} from "./assets";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

interface FormValues {
  title: string;
  answer: string;
}

export default class TestScoring extends ScoringController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderContent() {
    const { showIncorrectAnswers, questions, ModalOpen, selectedImage, testPercentage } = this.state;

    const displayedQuestions = showIncorrectAnswers
      ? this.getIncorrectQuestions()
      : questions;

    return (
      <Box className="messageContainer" style={{ flexDirection: 'column'}}>
        {showIncorrectAnswers && (
          <Box className="scoreHeaderStyle">
            <Typography className="hearderStyle-1" variant="h6">
              Below are the questions that were answered incorrectly. The correct answer is shown in green.
            </Typography>
            <Typography className="hearderStyle-2" variant="h6">
              Your Score <span style={{ fontWeight: 600 }}>{testPercentage}%</span>
            </Typography>
            <IconButton className="hearderStyle-3" onClick={this.handleReDoQuix}>
              <img src={ReDoButton} style={{marginRight: '6px'}} /><span>Re-Do Quiz</span> 
            </IconButton>
          </Box>
        )}

        <ScrollableContainer p={4} style={{ height: "430px"}}>
          {displayedQuestions.map((q, index) => (
            <Box key={q.id}>
              <Typography className="questionStyle" variant="h6">
                {index + 1}. {q.question}
              </Typography>

              {q.image && (
                <Box style={{ display: 'flex' }}>
                  {q.image.map((img, idx) => (
                    <img
                      key={idx}
                      src={String(img)}
                      onClick={() => this.openModal(img)}
                      style={{
                        width: "100%",
                        height: "101px",
                        maxWidth: "261px",
                        borderRadius: '6.5px',
                        margin: '8px 8px 8px 0',
                      }}
                    />
                  ))}
                </Box>
              )}

              <Box style = {{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
                {q.options.map((opt, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      q.type === "single" ? (
                        <Radio
                          onChange={() => this.handleOptionChange(q.id, opt.label, q.type)}
                          disabled={showIncorrectAnswers}
                          className={this.getCheckboxClass(showIncorrectAnswers)}
                          checked={this.isCheckboxChecked(showIncorrectAnswers, opt, q)}
                        />
                      ) : (
                        <Checkbox
                          onChange={() => this.handleOptionChange(q.id, opt.label, q.type)}
                          disabled={showIncorrectAnswers}
                          className={this.getCheckboxClass(showIncorrectAnswers)}
                          checked={this.isOptionChecked(showIncorrectAnswers, opt, q)}/>
                      )
                    }
                    label={
                      <Typography className="optionsStyle">
                        {opt.label}
                      </Typography>
                    }
                  />
                ))}
              </Box>
              <Box className="lineStyle"></Box>
            </Box>
          ))}
        </ScrollableContainer>

        {!showIncorrectAnswers && (
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className="numStyle" variant="h6">
              Num. of Ques.: {questions.length}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              className="quizButtonStyle"
            >
              Submit Answers
            </Button>
          </Box>
        )}

        <Dialog
          open={ModalOpen}
          onClose={this.closeModal}
          PaperProps={{
            style: {
              maxWidth: "700px",
              maxHeight: "548px",
              borderRadius: "12px",
              overflow: "hidden",
              width: '100%',
              height: '100%',
            },
          }}
        >
          <DialogContent style={{ position: "relative", padding: "2rem" }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5" className="previewStyle">
                Image Preview
              </Typography>
              <IconButton
                onClick={this.closeModal}
                style={{ position: "absolute", top: 25, right: 18 }}
              >
                <CloseIcon style={{ color: 'rgba(220, 38, 38, 1)', height: '21px', width: '21px' }} />
              </IconButton>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1.5rem' }}>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Preview"
                  style={{ width: "605px", height: "418px", borderRadius: '6px' }}
                />
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  renderScore() {
    const { questions, testScore, testPercentage} = this.state;
    return (
      <Box className="scoreMessageContainer" style={{ flexDirection: 'column', flexWrap: 'wrap' }}>
        {testScore === 0 ? "" :  <Typography variant="body1" className="successStyle" style={{ paddingTop: '4rem' }}>Congratulations!</Typography>}   
        {testScore !== null && (
          <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', marginTop: '1rem', fontSize: '20px' }}>
            You answered <span style={{ fontWeight: 600 }}> {testScore} out of {questions.length} </span>questions correctly.
          </Typography>
        )}
        <Typography variant="body1" className="messageStyle" style={{ wordWrap: 'break-word', paddingTop: '3rem', marginBlock: '1rem' }}>Your score is <span style={{ fontWeight: 600 }}>{testPercentage}%</span></Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={this.state.testPercentage === 100 ? () => {} : this.handleRightAnswer}
          className="buttonStyle"
        >
        {this.state.testPercentage === 100 ? 'Next' : 'See Right Answers'}
          <ArrowForwardIosIcon style={{ height: '13px', width: '13px', marginLeft: '1rem' }} />
        </Button>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <ThemeProvider theme={theme}>
          <MainSection>
            <Box>
              <Paper className="paperStyle" >
                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="h6" className="headerStyle" >
                    Course Name
                  </Typography >
                </Box>
                <Box className="downloadCourse" style={{ flexDirection: 'row-reverse', }}>
                  <Typography className="downloadCourseText">Download Course</Typography>
                  <CloudDownloadOutlinedIcon style={{ fontSize: '11px' }} />
                </Box>

                {this.state.reDoQuiz && this.renderContent()}
                {!this.state.reDoQuiz && this.state.showIncorrectAnswers && this.renderContent()}
                {!this.state.reDoQuiz && !this.state.showIncorrectAnswers && this.state.isScoreOpen && this.renderScore()}
                {!this.state.reDoQuiz && !this.state.showIncorrectAnswers && !this.state.isScoreOpen && this.renderContent()}

              </Paper>

            </Box>
          </MainSection>
        </ThemeProvider>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ScrollableContainer = styled(Box)(({ theme }) => ({
  gap: "8px",
  opacity: "0px",
  overflowY:"auto",
  "&::-webkit-scrollbar": {
    width: "13px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "transparent",
    border: "1px solid #CBD5E1",
  },
  "&::-webkit-scrollbar-thumb": {
    width: "13px",
    height: "43px",
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "#EDF1F6",
    border: "1px solid #94A3B8",
  },
}))



const MainSection = styled(Box)({
  "& .scoreHeaderStyle": {
    height: '65px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '2rem',
    zIndex: 9
  },
  "& .textButton": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '12px',
    color: 'rgba(26, 70, 156, 1)',
  },
  "& .hearderStyle-1": {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '10px',
    color: 'rgba(51, 65, 85, 1)',
    width: '266px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
    borderLeft: '4px rgba(26, 70, 156, 1) solid',
    borderRadius: '4px',
    paddingLeft: '1rem'
  },
  "& .hearderStyle-2": {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '10.47px',
    color: 'rgba(15, 23, 42, 1)',
  },
  "& .hearderStyle-3": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '12.25px',
    color: 'rgba(26, 70, 156, 1)',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  "& .headerStyle": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '12.25px',
    color: 'rgba(15, 23, 42, 1)',
    lineHeight: '26px'
  },
  "& .paperStyle": {
    width: '100%',
    borderRadius: '18px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
    padding: '2rem',
    boxShadow: 'none',
    height: '753px'
  },
  "& .downloadCourseText": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '9px',
  },
  "& .downloadCourse": {
    display: 'flex',
    color: 'rgba(26, 70, 156, 1)',
    gap: '4px'
  },
  "& .checkboxStyle": {
    color: "#b0bec5",
    padding: 0,
    marginLeft: '6px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  "& .checkboxStyle.Mui-checked": {
    color: "rgba(5, 150, 105, 1)",
  },
  "& .unCheckboxStyle": {
    color: "#b0bec5",
    padding: 0,
    marginLeft: '6px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  "& .unCheckboxStyle.Mui-checked": {
    color: "rgba(26, 70, 156, 1)",
  },
  "& .questionStyle": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgba(15, 23, 42, 1)',
    lineHeight: '24px'
  },
  "& .optionsStyle": {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '12px',
    color: 'rgba(51, 65, 85, 1)',
    lineHeight: '18px',
    paddingLeft: '6px'
  },
  "& .lineStyle": {
    border: '0.5px rgba(215, 215, 215, 0.5) solid',
    marginTop: '8px',
    marginBottom: '1rem',
  },
  "& .numStyle": {
    fontFamily: "Rubik",
    fontWeight: '400',
    fontSize: '16px',
    color: 'rgba(15, 23, 42, 1)',
    lineHeight: '24px'
  },
  "& .quizButtonStyle": {
    fontFamily: "Rubik",
    fontWeight: '500',
    fontSize: '12px',
    color: '#fff',
    backgroundColor: 'rgba(26, 70, 156, 1)',
    width: '100%',
    height: '30px',
    borderRadius: '30px',
    maxWidth: '153px',
    textTransform: 'none',
  },
  "& .buttonStyle": {
    fontFamily: "Rubik",
    fontWeight: '700',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: 'rgba(26, 70, 156, 1)',
    width: '100%',
    marginTop: '8px',
    borderRadius: '30px',
    textTransform: 'none',
  },
  "& .previewStyle": {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: '22.63px',
    color: 'rgba(15, 23, 42, 1)',
  },
  "& .messageContainer": {
    height: '641px',
    display: 'flex',
    paddingRight: '25px',
    paddingLeft: '25px',
    backgroundColor: 'white',
    borderRadius: '18px'
  },
  "& .scoreMessageContainer": {
    backgroundImage: `url(${imgProductCard})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 50px top 50px',
    height: '641px',
    display: 'flex',
    paddingRight: '25px',
    paddingLeft: '25px',
    backgroundColor: 'white',
    borderRadius: '18px'
  },

  "& .successStyle": {
    fontFamily: "Rubik",
    fontWeight: '500',
    fontSize: '24px',
    color: '#0F172A',
    textTransform: 'none',
  },
  "& .messageStyle": {
    fontFamily: "Rubik",
    fontWeight: '400',
    fontSize: '18px',
    color: '#475569',
  },
})

// Customizable Area End










