import React, { ComponentPropsWithoutRef, CSSProperties, ElementType, PropsWithChildren, ReactNode, useCallback, useState } from "react";
import { IconButton as MuiIconButton, styled } from "@material-ui/core";

const getButtonSize = (size?: "xs" | "sm" | "md" | "lg"): CSSProperties => {
    if (size === 'xs') {
        return { height: '24px', padding: '4px', gap: '10px', fontSize: "20px" }
    }
    if (size === 'sm') {
        return { height: '32px', padding: '8px', gap: '10px', fontSize: "24px" }
    }
    if (size === 'lg') {
        return { height: '48px', padding: '24x', gap: '10px', fontSize: "24px" }
    }
    return { height: '44px', padding: '20x', gap: '10px', fontSize: "24px" }
}

type ButtonSettingProps = {
    children?: ReactNode;
    size?: "sm" | "md" | "lg";
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    animation?: "progress" | "success" | "error" | "pulse" | boolean;
    disabled?: boolean;
    variant?: "fill" | "outline" | "primary" | "secondary" | "ghost";
    fullWidth?: boolean;
}
type ButtonProps<C extends ElementType> = {
    as?: C;
    fullWidth?: boolean;
    style?: CSSProperties;
} & ButtonSettingProps;
type IButtonProps<C extends ElementType> = PropsWithChildren<ButtonProps<C>> & Omit<ComponentPropsWithoutRef<C>, keyof ButtonProps<C>>

const IconButton = <C extends ElementType = "button">({
    children,
    size = "md",
    disabled,
    animation,
    as,
    style,
    ...rest
}: IButtonProps<C>) => {
    // const hasAnimationContent = animation === "progress" || animation === "success";

    return (
        <IconButtonComponent 
            style={{ 
                ...getButtonSize(size),
                // delay animation
                ...style
            }}
            {...((!as || as === 'button') && { type: 'button' })}
            {...(disabled && { disabled })}
            {...rest}
        >
            {children}
            <Hover />
        </IconButtonComponent>
    )
}

export default IconButton;

const IconButtonComponent = styled(MuiIconButton)({
    position: "relative",
    zIndex: 0,
    borderRadius: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    userSelect: 'none',
    color: "#1A469C"
})
const Hover = styled('span')({
    zIndex: -1,
    position: 'absolute',
    inset: 0,
    pointerEvents: 'none',
    transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms'
})