import { Box, List, ListItem, styled } from "@material-ui/core";
import  axios from 'axios';
import React, { CSSProperties, useRef, useState } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import 'react-quill/dist/quill.snow.css';

import Button from "./Button";

const formats = ["bold", "underline", "clean", "list", "bullet" ];

interface RichTextEditorProps extends ReactQuillProps {
    id?: string;
    name?: string;
    label?: string;
    labelStyle?: CSSProperties;
    bgColor?: string;
    errorText?: string;
}

const RichTextEditor = (props: RichTextEditorProps) => {
    const {  id, label, labelStyle, bgColor, errorText, value, onChange, ...richTextEditorProps } = props;

    const quillRef = useRef<ReactQuill>(null);

    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsShown, setSuggestionsShown] = useState(false);
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

    const checkSpelling = async (content: string) => {
        try {
            const response = await axios.get('https://api.textgears.com/spelling', {
                params: {
                    key: 'yJ85cVqAAMVkya7O',
                    text: content
                }
            });
    
            const suggestions1 = response.data.response.errors.map((error: any) => ({
                word: error.bad,
                suggestions: error.better,
            }));

            setSuggestions(suggestions1);
            return suggestions1;
        } catch (error) {
            console.log(error)
        }
    }
    const handleSelectionChange = (range: any) => {
        if (quillRef && range && range.length > 0) {
          const quill = quillRef?.current?.getEditor();
          if (quill) {
            const selectedText = quill.getText(range.index, range.length);
            checkSpelling(selectedText);
      
            const bounds = quill.getBounds(range.index, range.length);
      
            const suggestionBoxPosition = {
              top: bounds.top + bounds.height + window.scrollY + 290,
              left: bounds.left + window.scrollX + 150
            };
            setSelection(range);
            setSuggestionsShown(true);
            setSuggestionBoxPosition(suggestionBoxPosition)
          }
        } else {
            setSuggestionsShown(false);
        }
    }

    return (
        <Container>
            {label && <Label htmlFor={id} role="alert" style={labelStyle}>{label}</Label>}
            <Box onDoubleClick={() => setOpen(true)} onContextMenu={handleSelectionChange}>
                {open && (
                    <ModalBackdrop>
                        <ModalPanel>
                            <ReactQuill
                                ref={quillRef}                                
                                formats={formats}
                                modules={{ clipboard:{ matchVisual: false } }}
                                onChangeSelection={handleSelectionChange}
                                value={value}
                                onChange={onChange}
                                style={{ flexGrow: 1 }}
                                // className="modalTextEditor"
                            />
                            {suggestionsShown && (
                                <SuggestionBox style={{ top: suggestionBoxPosition.top, left: suggestionBoxPosition.left }}>
                                    <List>
                                    {suggestions.map((each: any, index: number) => (
                                        <>
                                            {each?.suggestions.map((suggestion: any, idx: number) => (
                                                <ListItem
                                                    key={index}
                                                    style={{
                                                        padding: '8px 12px',
                                                        // cursor: 'pointer',
                                                        borderBottom: '1px solid #eee'
                                                    }}
                                                    onMouseDown={() => {}}
                                                >
                                                    {suggestion}
                                                </ListItem>
                                            ))}
                                        </>
                                    ))}
                                    </List>
                                </SuggestionBox>
                            )}
                            <ModalFooter>
                                <Button variant="outline" onClick={() => setOpen(false)} style={{ borderRadius: '28.34px' }}>Cancel</Button>
                                <Button onClick={() => setOpen(false)} style={{ borderRadius: '28.34px' }}>Save</Button>
                            </ModalFooter>
                        </ModalPanel>
                    </ModalBackdrop>
                )}

                <ReactQuill
                    ref={quillRef}
                    formats={formats}
                    style={{ backgroundColor: bgColor }}
                    value={value}
                    onChange={onChange}
                    {...richTextEditorProps}
                />
            </Box>
            {errorText && <HintText>{errorText}</HintText>}
        </Container>
    )
}


export default RichTextEditor

const Container = styled(Box)({
    width: '100%',
    maxWidth: '100%'
})
const Label = styled('label')({
    fontFamily: 'Rubik',
    paddingBottom: '4px',
    color: '#334155',
    lineHeight: '22px'
})
const ModalBackdrop = styled(Box)({
    zIndex: 9999,
    position: 'fixed', 
    inset: 0, 
    background: '#00000050', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
})
const ModalPanel = styled(Box)({
    minWidth: 715, 
    height: 404, 
    borderRadius: "8px",
    background: '#fff', 
    display: 'flex',
    flexDirection: 'column'
})
const ModalFooter = styled(Box)({
    marginInline: '18px', 
    paddingBlock: '8px', 
    display: 'flex', 
    justifyContent: 'flex-end', 
    gap: '10px', 
    borderTop: '1px solid lightgray'
})
const SuggestionBox = styled(Box)({
    position: 'absolute',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    zIndex: 1000
})
const HintText = styled('p')({
    display: 'inline-block',
    marginTop: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#DC2626'
})