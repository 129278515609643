import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { Dimensions } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";

type ScoreOptionValue = number | string | boolean;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  scoreValue?: {
    score_options: {
      [key: string]: ScoreOptionValue;
    }[];
  };
  setError?: {
    score_title0?: string;
    score_title1?: string;
    score_title2?: string;
    score_title3?: string;
    score_title4?: string;
  };
  isEdit?: boolean;
  scoringQuestionFn: Function;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  myID: number;
  opponentID: number;
  score: {
    first_user_score: number;
    second_user_score: number;
  };
  token: string;
  score_options?: {
    [key: string]: ScoreOptionValue;
  }[];
  option?: {
    [key: string]: ScoreOptionValue;
  }[];
  erorrs: {
    [key: string]: ScoreOptionValue;
  }[];
  firstUserName: string;
  secondUserName: string;
  isModalOpen: boolean;
  isPortrait: boolean;
  questions: Question[];
  testScore: number | null;
  ModalOpen: boolean;
  selectedImage: string | null;
  isScoreOpen: boolean;
  showIncorrectAnswers: boolean;
  testPercentage: number;
  reDoQuiz: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface DimensionType {
  screen: Screen;
  window: Screen;
}

export interface Screen {
  fontScale: number;
  height: number;
  scale: number;
  width: number;
}

interface Option {
  label: string;
  isCorrect: boolean;
};

type QuestionType = "single" | "multi";

interface Question {
  id: number;
  type: QuestionType;
  question: string;
  image?: string[];
  options: Option[];
  selectedOption: string | string[];
};

const dummyQuestions: Question[] = [
  {
    id: 1,
    type: "multi",
    question: "What is the most common method for extracting oil from deep underground reservoirs?",
    image: [imgPasswordInVisible, imgPasswordVisible],
    options: [
      { label: "Hydraulic Fracturing (Fracking)", isCorrect: true },
      { label: "Horizontal Drilling", isCorrect: false },
      { label: "Primary Recovery", isCorrect: true },
      { label: "Steam Injection", isCorrect: false },
    ],
    selectedOption: [],
  },
  {
    id: 2,
    type: "single",
    question: "What is the main component of natural gas?",
    options: [
      { label: "Propane", isCorrect: false },
      { label: "Methane", isCorrect: true },
    ],
    selectedOption: "",
  },
];
// Customizable Area End

export default class ScoringController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  deleteOptionCallId = "";
  getReasonId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    let tempScoreOptionChecked = this.props.isEdit
      ? this.props?.scoreValue?.score_options?.map(item => {
        return {
          ...item,
          checked: typeof item === "number" ? item?.["answer"] > 0 : false
        };
      })
      : this.props?.scoreValue?.score_options?.map(item => {
        return {
          ...item,
          answer: item.answer ? item.answer : "0",
          checked: false
        };
      });

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      myID: 0,
      opponentID: 0,
      score: {
        first_user_score: 0,
        second_user_score: 0
      },
      token: "",
      score_options: tempScoreOptionChecked ? tempScoreOptionChecked : [],
      erorrs: [
        { value: "", title: "" },
        { value: "", title: "" }
      ],
      firstUserName: "",
      secondUserName: "",
      isModalOpen: false,
      isPortrait: this.isPortrait(),
      questions: dummyQuestions,
      testScore: null,
      ModalOpen: false,
      selectedImage: null,
      isScoreOpen: false,
      showIncorrectAnswers: false,
      testPercentage: 0,
      reDoQuiz: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId == this.getReasonId) {
          this.setState({
            score: {
              first_user_score:
                responseJson.first_user_score.data.attributes.score,
              second_user_score:
                responseJson.second_user_score.data.attributes.score
            },
            firstUserName:
              responseJson.first_user_score.data.attributes.user_name,
            secondUserName:
              responseJson.second_user_score.data.attributes.user_name
          });
        } else if (apiRequestCallId == this.deleteOptionCallId) {
          toast.success("Option Deleted Successfully");
        }
      } else {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData("authToken");
    if (token == null) {
      this.props.navigation.navigate("Home");
    } else {
      let loggedInUserId = await getStorageData("userId");
      this.setState(
        { token, myID: loggedInUserId, opponentID: configJSON.opponentID },
        () => {
          this.getScoreData(configJSON.gameID);
        }
      );

      Dimensions.addEventListener("change", this.getDimentions);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidUpdate(prevProps: {}, prevState: any) {
    if (prevState.reDoQuiz !== this.state.reDoQuiz && this.state.reDoQuiz) {
      console.log('Re-rendering due to reDoQuiz state update');
      this.setState({ reDoQuiz: false,});
    }
  }
  
  getScoreData = async (gameId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReasonId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserGameScoreApiEndpoint}?game[game_type_id]=${gameId}&account_id=${this.state.opponentID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDimentions = () => {
    this.setState({ isPortrait: this.isPortrait() });
  };

  isPortrait = () => {
    const dimension = Dimensions.get("screen");
    return dimension.height >= dimension.width;
  };

  changeChecked = (index: number, checked: boolean) => {
    let tempArr =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [];
    if (tempArr.length > 0) {
      tempArr[index]["checked"] = checked;
    } else {
      tempArr = [];
    }

    this.setState({ ...this.state, score_options: tempArr });
  };

  deleteOptions = (deletionId: number, scoringQuestionFn: Function) => {
    let tempArr =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [];
    if (tempArr.length > 0) {
      const scoringIndex = tempArr.findIndex(item => item.id === deletionId);
      if (scoringIndex !== -1) {
        tempArr[scoringIndex]["is_deleted"] = true;
      }
      this.setState({ ...this.state, score_options: tempArr });
      scoringQuestionFn(tempArr);
    } else {
      tempArr = [];
      this.setState({ ...this.state, score_options: tempArr });
    }
  };

  handleEdit = (index: number, scoringQuestionFn: Function) => {
    if (!this.props.isEdit) {
      let tempArr =
        this.state.score_options && this.state.score_options.length
          ? this.state.score_options
          : [];
      let error =
        this.state.erorrs && this.state.erorrs.length ? this.state.erorrs : [];
      if (tempArr.length > 2) {
        tempArr.splice(index, 1);
        error.splice(index, 1);

        this.setState({ ...this.state, score_options: tempArr, erorrs: error });
        this.props.scoringQuestionFn(tempArr);
      }
    } else {
      this.deleteOptions(
        this.props.scoreValue?.score_options[index].id as number,
        scoringQuestionFn
      );
    }
  };

  addOption = (scoringQuestionFn: Function) => {
    const initailScoringData =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [];
    const scoreData = { title: "", answer: "0", checked: false };
    const erorrs =
      this.state.erorrs && this.state.erorrs.length ? this.state.erorrs : [];
    erorrs.push({ title: "", answer: "" });
    initailScoringData.push(scoreData);
    this.setState({
      score_options: initailScoringData,
      erorrs
    });
    scoringQuestionFn(initailScoringData);
  };

  handleChange = (
    event: number | string,
    index: number,
    name: string,
    scoringQuestionFn: Function
  ) => {
    const option =
      this.state.score_options && this.state.score_options.length
        ? this.state.score_options
        : [{ value: "", title: "" }];
    option[index][name] = event;
    if (name === "answer" && 100 < Number(event)) {
      const error =
        this.state.erorrs && this.state.erorrs.length
          ? this.state.erorrs
          : [{ value: "", title: "" }];
      error[index]["value"] = "Max number is 100";
      this.setState({
        erorrs: error
      });
      return false;
    }

    this.setState({
      score_options: option
    });
    scoringQuestionFn(option);
  };

  determineWinner() {
    const { firstUserName, secondUserName, score } = this.state;

    if (score.first_user_score > score.second_user_score) {
      const winnerName =
        firstUserName.length > 15
          ? `${firstUserName.slice(0, 15)}...`
          : firstUserName;
      const loserName =
        secondUserName.length > 15
          ? `${secondUserName.slice(0, 15)}...`
          : secondUserName;
      return `${winnerName} has won by ${loserName}`;
    } else if (score.first_user_score < score.second_user_score) {
      const winnerName =
        secondUserName.length > 15
          ? `${secondUserName.slice(0, 15)}...`
          : secondUserName;
      const loserName =
        firstUserName.length > 15
          ? `${firstUserName.slice(0, 15)}...`
          : firstUserName;
      return `${winnerName} has won by ${loserName}`;
    } else {
      return "Game Tied";
    }
  }

  handleOptionChange = (
    questionId: number,
    optionLabel: string,
    questionType: "single" | "multi"
  ) => {
    this.setState((prevState) => {
      const updatedQuestions = prevState.questions.map((q) => {
        if (q.id === questionId) {
          return {
            ...q,
            selectedOption: this.updateSelection(q.selectedOption, optionLabel, questionType),
          };
        }
        return q;
      });
  
      return { questions: updatedQuestions };
    });
  };
  
  updateSelection = (
    selectedOption : any,
    optionLabel : any,
    questionType: "single" | "multi"
  ) => {
    if (questionType === "single") {
      return optionLabel; 
    }
  
    if (Array.isArray(selectedOption)) {
      return this.updateMultiSelection(selectedOption, optionLabel);
    }
  
    return [optionLabel];
  };
  
  updateMultiSelection = (selectedOption: string[], optionLabel: string) => {
    const isSelected = selectedOption.includes(optionLabel);
    return isSelected
      ? selectedOption.filter((opt) => opt !== optionLabel) 
      : [...selectedOption, optionLabel]; 
  };
  
  handleSubmit = () => {
    const correctAnswers = this.state.questions.filter((q) => {
      if (q.type === "single") {
        return q.options.some((opt) => opt.label === q.selectedOption && opt.isCorrect);
      } else if (q.type === "multi" && Array.isArray(q.selectedOption)) {
        const correctOptions = q.options.filter((opt) => opt.isCorrect).map((opt) => opt.label);
        const selectedOptions = q.selectedOption;
  
        return (
          correctOptions.length === selectedOptions.length &&
          correctOptions.every((opt) => selectedOptions.includes(opt))
        );
      }
      return false;
    }).length;
  
    const totalQuestion = this.state.questions.length;
    const percentage = totalQuestion > 0 ? parseFloat(((correctAnswers / totalQuestion) * 100).toFixed(2)) : 0;
  
    this.setState({ testScore: correctAnswers, testPercentage: percentage, isScoreOpen: true });
  };

  handleRightAnswer = () => {
    this.setState({ showIncorrectAnswers: true });
  }
  openModal = (image: any) => {
    this.setState({ ModalOpen: true, selectedImage: image });
  };

  closeModal = () => {
    this.setState({ ModalOpen: false, selectedImage: null });
  };

  getIncorrectQuestions() {
    return this.state.questions.filter((q) => {
      if (q.type === "single") {
        return !q.options.some((opt) => opt.isCorrect && opt.label === q.selectedOption);
      } else if (q.type === "multi") {
        const correctOptions = q.options
          .filter((opt) => opt.isCorrect)
          .map((opt) => String(opt.label));
        const selectedOptions = Array.isArray(q.selectedOption)
          ? q.selectedOption.map((opt) => String(opt))
          : [];
        const hasExtraSelections = selectedOptions.some((opt) => !correctOptions.includes(opt));
        const isMissingCorrect = correctOptions.some((opt) => !selectedOptions.includes(opt));

        return hasExtraSelections || isMissingCorrect;
      }
      return false;
    });
  }

  handleReDoQuix = () => {
    this.setState({reDoQuiz: true, showIncorrectAnswers: false, testScore: null, testPercentage: 0, isScoreOpen: false, questions: dummyQuestions})
  }

  getCheckboxClass = (showIncorrectAnswers: any) => {
    return showIncorrectAnswers ? "checkboxStyle" : "unCheckboxStyle";
  }
  
  isCheckboxChecked = (showIncorrectAnswers: any, opt: any, q: any) => {
    return showIncorrectAnswers ? opt.isCorrect : q.selectedOption === opt.label;
  }
  
  isOptionChecked = (showIncorrectAnswers: any, opt: any, q: any) => {
    return showIncorrectAnswers 
      ? opt.isCorrect 
      : Array.isArray(q.selectedOption) && q.selectedOption.includes(opt.label);
  }
  
  // Customizable Area End
}
