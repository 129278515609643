import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ApiCallInterface, AssessmentItemInterface, AttemptedAssessmentInterface } from './IAssessment'
import StorageProvider from "../../../framework/src/StorageProvider";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
interface Choice {
  content: string;
  is_correct: boolean;
}

interface Question {
  content: string;
  description: string;
  question_type: string;
  choices_attributes: Choice[];
}

interface Quiz {
  title: string;
  passing_grade: number;
  course_id: number;
  questions_attributes: Question[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AssessmentItemInterface[],
  assessmenttestList: AssessmentItemInterface[],
  isError: boolean;
  assessmentCategories: AssessmentItemInterface[];
  isSuccess: boolean;
  selectedCategory: string | undefined;
  selectedId: string | number | undefined;
  tabNum: number
  attemptedAssessment: AttemptedAssessmentInterface[];
  isLoading: boolean;
  darkTheme:boolean;
  openQuizSection:boolean;
  quizTabs:number;
  quizTitle:string;
  passingGrade:number;
  courseId:number;
  allQuestions:Question[];
  selectedQuestionType:string;
  fileData:{fileName:string,filesize:number,fileType:string};
  uploadCSV:Blob|null;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentCategories: string = "";
  apiGetAssessmentByCategory: string = "";
  apiAttemptedAssessment: string = "";
  addQuizPostAPICallId:string="";
  addImportFileAPICallId:string="";
  quillRef:any="";
  fileRef:any="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      assessmentList: [],
      assessmenttestList: [],
      isError: false,
      isLoading: true,
      isSuccess: false,
      assessmentCategories: [],
      selectedCategory: '',
      selectedId: '0',
      tabNum: 1,
      attemptedAssessment: [],
      darkTheme:false,
      openQuizSection:false,
      quizTabs:2,
      quizTitle:"",
      passingGrade:0,
      courseId:0,
      allQuestions:[],
      selectedQuestionType:"Question Type",
      fileData:{
        fileName:"",
        filesize:0,
        fileType:""
      },
      uploadCSV:null,
    }
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.quillRef = React.createRef();
    this.fileRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


// Customizable Area Start
  async componentDidMount() {
    this.getAssessmentCategoriesApi()

    this.getAttemptedAssessmentApi()
    this.checkDarkMode()
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.assessmentCategories !== this.state.assessmentCategories) {
      this.setState({
        selectedId: `${this.state?.assessmentCategories![0]?.id}`
      })
    }

  }

  apiAssessmentCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await StorageProvider.get("USER_TOKEN")
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiStatement(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleApiStatement(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetAssessmentByCategory) {
        this.getAssessmentByCategorySuccesscallBack(responseJson.data);
      }
      else if (apiRequestCallId === this.apiGetAssessmentCategories) {
        this.getCatSuccesscallBack(responseJson.data,)
      }
      else if (apiRequestCallId === this.apiAttemptedAssessment) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
      }
      else if (apiRequestCallId === this.addQuizPostAPICallId) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
      }
    }  else if (errorReponse) {
      this.setState({
        isLoading: false,
        
        isSuccess: false,
        isError: true,
      })
    }
  }


  getAssessmentCategoriesApi = async () => {
    this.apiGetAssessmentCategories = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAssessementTestCategoriesUrl,
    });
  };
  getAssessmentByCategoryApi = async () => {
    this.apiGetAssessmentByCategory = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAllAssessementTestUrl,
    });
  };
  getAttemptedAssessmentApi = async () => {
    this.apiAttemptedAssessment = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAttemptedAssessmentsUrl,
    });
  };

  getAssessmentByCategorySuccesscallBack =  (data: AssessmentItemInterface[]) => {
    let filterData = data.filter((item: AssessmentItemInterface) => {
      {
        return item.attributes?.assessment_type === this.state.selectedCategory
      }
    })
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,
      assessmentList: data,
      assessmenttestList: filterData
    })
  };

  getCatSuccesscallBack =  (data: AssessmentItemInterface[]) => {
    this.setState({
      assessmentCategories: data,
      selectedCategory: data[0].attributes?.name,
      selectedId: data[0].id
    },
      () => {
        this.getAssessmentByCategoryApi()
      }
    )
  };


  getAttemptedAssessmentApiSuccesscallBack =  (data: AttemptedAssessmentInterface[]) => {
    this.setState({
      attemptedAssessment: data
    })
  };

  
  apiCall = async (data: { [key: string]: any }) => {
    const { method2, endPoint2, body2, type2, contentType2 } = data;
    let apiBody = body2;
    if (type2 === '') {
      apiBody = JSON.stringify(body2);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType2,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method2
    );
    body2 &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };


  addNewQuiz = async () => {
    let body = {
      "quiz": {
        "title": "First Quiz",
         "estimation_time": 30,
        "marks_as_point": 100,
        "passing_grade": this.state.passingGrade,
        "course_id": 255,
        "questions_attributes": this.state.allQuestions
      }
    }
    this.addQuizPostAPICallId = await this.apiCall({
      method2: configJSON.postMethod,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.postQuizURL,
      body2: JSON.stringify(body)
    });
  }
  sendFile=async()=>{
    let body = {
      "file":this.state.uploadCSV
    }
    let data = new FormData();
    data.append("file",this.state.uploadCSV as Blob);
    this.addImportFileAPICallId = await this.apiCall({
      method2: configJSON.postMethod,
      contentType2: configJSON.validationApiContentType,
      endPoint2: configJSON.postQuizImportURL,
      body2: data
    });
  }
  onSelected(item: AssessmentItemInterface) {
    this.props.navigation.navigate('AssessmentDetail', { id: item.id })
  }
  onAttemptedSelected(item: AttemptedAssessmentInterface) {
    this.props.navigation.navigate('AssessmentResult', { id: item.attributes?.assessment_id })
  }

  onReFetch() {
    this.setState({
      isLoading: true,
      isError: false,
    }, () => {
      this.getAssessmentCategoriesApi()
    }

    )
  }

  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }


  onTabClick(numInput: number) {
    this.setState({
      tabNum: numInput
    })
  }
  handleCatSelected(item: AssessmentItemInterface) {
    this.setState({ selectedId: `${item?.id}` })
    let list = this.state.assessmentList?.filter((newItem: AssessmentItemInterface) => {
      return newItem?.attributes?.assessment_type === item.attributes?.name
    })

    this.setState({
      assessmenttestList: list
    })
  }

  handleSearch = (text: string) => {
    if (text.trim() === '') {
      let first = this.state.assessmentList.filter((item: AssessmentItemInterface) => {
        {
          return item?.attributes?.assessment_type === this.state.selectedCategory
        }
      })

      this.setState({
        assessmenttestList: first
      })
    } else {
      const filtered = this.state.assessmenttestList.filter((item: AssessmentItemInterface) => {
        const name = item.attributes?.name?.toLowerCase() ?? 'Unknown';
        return name?.includes(text.toLowerCase())
      });
      this.setState({
        assessmenttestList: filtered
      })
    }
  }
  checkDarkMode=()=>{
    let mode = JSON.parse(localStorage.getItem('darkMode')!);
    if(mode)
      this.setState({
        darkTheme:mode
      })
  }
  unCompletedListAssessment(){
    let unCompletedList: AssessmentItemInterface[] = this.state.assessmenttestList.filter((item) => item.attributes?.is_completed !== true)
    return unCompletedList
  }
  uploadCSVQuizFile=(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    if(this.fileRef.current){
      this.fileRef.current.click();
    }
  }
  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ fileData:{
        fileName:file.name,
        filesize:file.size,
        fileType:file.type
      },uploadCSV:file });
    }
  };
  handleQuizSection=()=>{
    this.setState({
      openQuizSection:true,quizTabs:0
    },()=>{this.handleAddQuestions()})
    
  }
  handleQuizTabs=(num:number)=>{
    this.setState({
      quizTabs:num
    })
  }
  handleAddQuestions=()=>{
    const newQuestion:Question={
      content:'',
      description:'',
      question_type:'',
      choices_attributes:[
        {content:'',is_correct:false},
      ]
    }
    this.setState((prevState)=>({
      allQuestions:[...prevState.allQuestions,newQuestion],
    }))
  }
  handleQuestionAddition=(event:any,index:number)=>{
    let newQuestions = this.state.allQuestions;
    let updatedQuestion = event?.target?.value;
    newQuestions[index].content=updatedQuestion;
    this.setState({
      allQuestions:newQuestions
    })
  }
  handleChoiceAddition=(event:any,index:number,content_index:number)=>{
    let newContent = this.state.allQuestions;
    let updatedContent = event?.target?.value;
    newContent[index].choices_attributes[content_index].content=updatedContent;
    this.setState({
      allQuestions:newContent
    })
  }
  handleAnswerAddition=(event:any,index:number,content_index:number)=>{
    let newAnswers = this.state.allQuestions;
    let updatedAnswer;
    if(newAnswers[index].question_type==="yes_no")
      {
        newAnswers[index].choices_attributes.forEach((choice)=>{
          choice.is_correct=false
        })
      }
    if(newAnswers[index].choices_attributes[content_index].is_correct){
      updatedAnswer = false;
    } else {
      updatedAnswer = true;  
    }
    
    newAnswers[index].choices_attributes[content_index].is_correct=updatedAnswer;
    this.setState({
      allQuestions:newAnswers
    })
  }
  handleNewOptionAddition=(index:number)=>{
    this.setState((prevState)=>{
      let updatedOptions = [...prevState.allQuestions];
      updatedOptions[index].choices_attributes.push({content:"",is_correct:false});
      return {allQuestions:updatedOptions}
    })
  }
  handleQuestionTypeChange=(event:any,index:number)=>{
    let newQuestionType = this.state.allQuestions;
    newQuestionType[index].question_type=event.target.value;
    this.setState({
      allQuestions:newQuestionType
    })
  }
  // Customizable Area End
}
